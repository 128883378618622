export default {
  "intro.headline": `Welcome to the website 
  
  of Babek Peter Padar!`,

 "intro.text": `
  `, 
 
  "intro.picture1": `Certificate of expatriation from Iranian citizenship.

   `, 
    "intro.picture2": `Newspaper article after the November 13, 1989 robbery

   `,       
        
  "about.headline": `About Babek Peter Padar`,
  
  "about.text": `
  Babek Peter Padar is a German investor and descendant of His Imperial Majesty Fath-Ali Shah Qajar, Emperor of Iran.
  
  He was born on June 11th in 1970, in Berlin (West) as the son of his father Ramazan Padar (*May 1, 1922, in Baku, Azerbaijan; Ɨ January 16, 2007, in Berlin) and his mother Mahindokht Padar (*September 23, 1947, in Ardabil, Iran).
  
  Because of political conflicts, his father was fully expropriated and forced to leave Iran to find exile in Germany in 1956. He was allowed to take a mere 100 US Dollars along. Iran expatriated him and his entire family on September 9, 1980. His family belongs to the few who were ever expatriated from Iran.
  
  During the Cold War, Babek Peter Padar grew up in Berlin (West) as an Iranian citizen. On November 17, 1980, he received German citizenship. He possesses no other citizenship. He speaks four languages (German, English, Turkish and Persian) and is of Muslim faith.
  
  
  `,

  "selfmade.headline": `Self-made Man – Despite large family  `,
  "selfmade.text": `
  After his arrival in Berlin (West), his father started to work as a businessman and later invested in real estate. Eventually, Ramazan Padar became a successful businessman in the real estate sector. He gave all the essential knowledge to his young son and taught him how to use his opportunities with ambition and through hard work.
  At an early age, Babek Peter Padar developed a great interest and strong ambition in this field. At the age of 12, he set up his first rental agreement for his father, at his own request. Nevertheless, he began a career of his own.
 `,

  "juwelier.headline": `The Youngest Jeweler of Berlin`,
  "juwelier.text": `
  In 1988, at the age of only 18, Babek Peter Padar became Berlin´s youngest jeweler. He opened a jewelry store, while still attending school. One year earlier, he had begun a partnership with another Berlin jeweler, dissolving the partnership soon thereafter. He ran the business as the sole owner from 1989 to 1991. 
  `,

  "wurzeln.headline": `Back to the Roots`,
  "wurzeln.text": `
  Between 1994 and 2004 Babek Peter Padar became his father's wealth manager. In 2004 he began to invest in real estate and company holdings and has been – since then – an internationally successful investor.
  `,

  // Akkordeon - Familie und Co
  "akkordeon.familie.headline": `Family and Family Tree`,
  "akkordeon.familie.text": `
  Babek Peter Padar is married to Leyla Padar, neé Tunç. He met his wife in March 1989 in Berlin (West). The young woman, third child of her father Veli Tunç (*June 10, 1941, in Varna, Bulgaria; Ɨ September 1, 2004, in Corlu, Turkey) and her mother Ayse Tunç, neé Akdogan (*March 23, 1944, in Misinli, Turkey) was born in Berlin (West) on October 6, 1972, as a Turkish citizen. They first met in his jewelry store. Leyla Tunç entered the store as a customer. As he stood behind the vitrines, he decided to take this young woman as his wife. He served his customer all in all six times, each time for five minutes, before the eighteen-year-old Babek Peter Padar proposed to marry her. The 16-year-old Leyla Tunç agreed immediately. After a short time, on December 8 in 1989, they had their civil wedding in Berlin (West). The wedding celebration took place in the "Schloss-Saal" Ballroom of Bristol Hotel Kempinski (Kurfürstendamm/Fasanenstrasse) on January 12 in 1990. On June 9 in 1994, Leyla Padar became a German citizen.
  
  Leyla and Babek Peter Padar have two children who were born in Berlin: son Serdar-Abbas Kevin, born on October 18 in 1991, and daughter Isabelle Fatima-Soraya, born on November 24 in 1998. Both study in the German capital: Law and Business Administration respectively.
  `,

  // Akkordeon - Geschichte
  "akkordeon.geschichte.headline": `The Qajars and their Significance`,
  "akkordeon.geschichte.text": `
  For a period of 131 years, the emperors of Iran came from one dynasty: the Qajars, a Turkish tribe of Oghuz (subgroup: Bozok, sub-tribe: Bayat, forearm: Jalayirid). The Turkish Seljuks (subgroup: Üçok, subtribe: Kınık), as well as the Afsharid (subgroup: Bozok, subtribe: Afshar) and the Ottomans (subgroup: Bozok, subtribe: Kayı) were the offspring of the Oghuz tribe. Consequently, Qajars and Ottomans are parallel tribes.
  
  Seven emperors of the Qajar family reigned over Iran between 1794 and 1925.
               
  #### The Emperors of the Qajar Dynasty 
  
  1. Aga Mohammad Shah Qajar (reigned between 1796 and 1797)
  2. Fath-Ali Shah Qajar (reigned between 1797 and 1834)
  3. Mohammad Shah Qajar (reigned between 1834 and 1848)
  4. Naser ad-Din Shah Qajar (reigned between 1848 and 1896)
  5. Mozaffar ad-Din Shah Qajar (reigned between 1896 and 1907)
  6. Mohammad Ali Shah Qajar (reigned between 1907 and 1909)
  7. Ahmad Shah Qajar (reigned between 1909 and 1925)

  
  The founder of the dynasty was Aga Mohammad Khan Qajar (later Emperor Aga Mohammad Shah Qajar), who waged war for many years and through whom the present borders of Iran came into existence. In 1796, Aga Mohammad Khan Qajar became the emperor of Iran and subsequently called himself Aga Mohammad Shah Qajar. His capital was Tehran, at that time still a village without any historical relevance.
  
Under him and his successor Emperor Fath-Ali Shah Qajar (his nephew), Iran, which was about to disintegrate under the leaders of the Zand Dynasty, was unified again. Without the Qajar takeover, Iran would have already ceased to exist existence in the 18th century and would not be Iran, as we know it today.
  
   
  #### Iran´s Constitutional Monarchy
  
  Under Emperor Mozaffar ad-Din Shah Qajar, Iran underwent  - as the first Islamic state – the change from an absolute to a constitutional monarchy.
        
 The emperor, who was more reformative than his predecessors, signed the constitution, which came into effect on December 30 in 1906. 
        
  The emperor at that time, Mozaffar ad-Din Shah Qajar, agreed in 1906 to elect a parliament (Madschles), which passed a constitution (Qanun-e Asasi) containing basic civil rights. Consequently, Iran became, as the first Islamic state, a democracy with an elected parliament and a constitution. 
        
  After the political change, the strong, prevailing influence of the colonial powers of Great Britain and Russia increased.
        
  In 1908 the first oil deposits were discovered in Iran. 
  This fact turned out to be a disastrous stroke of fate, which did not bring peace to the country. 
  Since the young democracy appeared as an obstacle for the colonial powers in their efforts to exploit Iran for its oil, Britain feverishly developed a plan to force the country into submission. 
        
  In order to resist the colonial powers together, the people of Turkish descent who lived in Iran formed stronger cooperation with Turkey. The blooming dominance of the people of the region, who they wanted to exploit, made Britain fearful. They did not want these two countries to unify to a big empire with federal structures, as this would have led to the formation of a new and stronger superpower than the recently dissolved Ottoman Empire.
  
  Under Qajar rule, Iran had a liberal, multicultural, and multiethnic society without discrimination.

  The Aryan Theory was already known in Europe at the beginning of the 20th century and offered a good opportunity to divide the people by spreading hatred and contempt. Britain used aspects of ancient Persian mythology, which never had this kind of meaning, to invent Pan-Iranism. There are no similarities or connections between Pan-Iranism and the Iranian culture. Similar theories like Pan-Arabism had already been successfully used in the destruction of the multicultural Ottoman Empire. Since Iran was also a multicultural country, whose people lived peacefully together, plans for the same agenda of destruction were made.

  For these reasons, Britain realized that only vassals of non-Turkish origin would support their plans of submission.

  The British secret service promoted Reza, a rude, illiterate man and former stable boy of the Qajars, who ascended to the position of a commander of the Cossacks, as their successor. Britain planned to establish an obedient Iranian vassal-state through Reza. 
        
  With a military coup organized by Britain, Reza ended the young democracy violently in 1925 and, by threatening violence, forced the parliament to appoint him as the absolute ruler. The Pahlavi nickname was given to Reza by the British agent Ardashir Reporter. 
  
  Many high-ranking Qajar members fled to foreign countries, went underground, or were arrested. On the other hand, some low-ranking Qajars collaborated with Reza Pahlavi, hoping for financial and social advantages, which they would not have received – due to their low ranks – under Emperor Ahmad Shah Qajar. Emperor Ahmad Shah Qajar, the last legitimate emperor of Iran, who happened to be in France for medical treatment during the coup, never returned. The dynasty of the illegitimate successor Reza, who then called himself Reza Shah Pahlavi, „Light of the Aryans“, was never recognized by him. 

  Due to his support for Hitler, Reza Pahlavi was overthrown by Britain and exiled to South Africa, where he died three years later. His son, Mohammad Reza Pahlavi, who also called himself „Light of the Aryans”, was a ruthless dictator who, like his father, was overthrown by the people in 1979 and fled to Egypt. Like his father, he died in Africa and was buried there.


  #### From Tehran to Berlin – his Father´s Exile
        
  Due to Reza Pahlavi´s closeness to Hitler, Iran was occupied by the Soviet Union and Great Britain, with internal conflicts and separatism raging as a result of his fascist dictatorship. 
      
Babek Peter Padar´s father, Ramazan Padar, a citizen of Iran, was born on May 1 in 1922 in Baku, Azerbaijan. Prior to that, Ramazan Padar´s parents had moved from Iran to Baku, in order to help build the local oil industry.
  
  The Democratic Republic of Azerbaijan was founded in 1918 after the disintegration of the Russian Empire. It was recognized under international law as a sovereign state with Baku as its capital and was occupied and annexed in contradiction to international law by the Soviets in 1920.
  
  Since the family members were citizens of Iran as well as aristocrats, they were deprived of all their belongings and arrested by the Soviet Secret Service NKWD in 1938 in a cloak-and-dagger operation and deported to Iran. There, the family was given the arbitrarily chosen family name Padar against their will. Padar is a region in Azerbaijan. When the family refused to take this name, they were threatened with arrest.
  
  In addition, Ramazan Padar fought in Iran not only for the sovereignty of his country, but against the Pahlavi dictatorship as well. In the 1950s, Mohammad Mossadegh (also an descendant of the Qajars) was elected by the people as Prime Minister of Iran and was a co-founder of the National Frontier. The conflict of the theft of Iranian oil by Britain developed into an international crisis, which reached its climax in the nationalization of Iranian oil by Prime Minister Mohammad Mossadegh. 
  
  With Operation Ajax in 1953, the CIA finally overthrew the government of Prime Minister Mohammad Mossadegh, which had been elected by the people. Consequently, the regime of dictator Pahlavi was established for good by western secret services.
  
  Due to the ongoing persecution and second deprival of the entire family´s possessions – this time by the Pahlavi dictatorship – Ramazan Padar left with only 100 Dollars into exile in Berlin in 1956, where his older brother was already living.
  `,

  "akkordeon.residenzen.headline": `Residences, Art and Family Possessions `,
  "akkordeon.residenzen.text": `
  The imperial family brought many art lovers with them. More than 60 palaces, innumerable crown jewels, and other treasures still exist. Here is a partial selection of them.

  #### The Qajars´ Coat of Arms
  
  The lion and the sun mark the coat of arms of the Qajar families. The lion is the national animal of Iran; the rising sun is a symbol of Shiite Islam.
  
  
  #### Imperial Palaces in Iran: Cultural Heritage
    
  ##### Golestan Palace
  
  The palace of the Qajars government was built in Tehran around the turn of the 18th to the 19th century. Since 2013, the Golestan Palace is part of UNESCO´s World Heritage.
  
  Tahmasp I, emperor of the Safavid dynasty, started with the construction of the citadel, which reached its importance under Aga Mohammad Khan as the official residence of the Qajars. The architecture and layout of the palace continued to be further developed by the following members of the dynasty. Today, the palace still has a size of 4.5 hectares and is open to the public.
    
  ##### The Palace Complex of Saadabad
  
  The palace complex of Saadabad includes 18 palaces and approximately 110 hectares of land. It was the summer residence of the Qajar emperors. The largest palace, the White Palace, and the oldest, the Green Palace, belong to the special sites; in addition, there is the Museum of Fine Arts.
  `,
        
// Akkordeon - Wappen
  "akkordeon.wappen.headline": `The „Lion and Sun“ Motif

  or Icon in Turkic Civilization

`, 
        
   "akkordeon.wappen.text":`
  According to a common misconception, the „Lion and Sun“ motif on the Pahlavi Era Iranian flag did not emerge from the Turkic peoples. When we observe the historical evidence, however, we find that it does indeed stem from the culture and civilizations of the Turkic peoples. The „Lion and Sun“ motif is frequently found on currency, tombstones, architecture, chalices, glassworks, tiles, flags and other artifacts from many eras of Turkic history and mythology.

  At no time can this motif be classified as belonging to either Persian culture or the Zoroastrian religion. It is found neither in Persepolis nor in Yazd nor in other strongholds of Persian culture.

  In fact, Persian-nationalist demagogue Muctaba Minevi, who knew about the Turkic origins of the „Lion and Sun“ motif, maintained that it should be eliminated from Iranian culture altogether.*
   
  *(Source: Muctabâ Mînevî, Târîh ve Ferheng, Çâp: 3, Şirket-i Sahâmî-yi İntişârât-ı Hârezmî, Tahran, h. ş. 1369, s. 352, 359, 374, 378.)
        
  We can name the following historic Turkic nations as having used the „Lion and Sun“ motif: 
        
  Kushan Empire (30-375), Turk Shahi dynasty (665-850), Ghaznavid dynasty (963-1186), Seljuk Empire (1037-1194), Sultanate of Rum (1077-1307), Khwarazmian Empire (1077-1231), Mamluk Sultanate (1250-1517), Ilkhanate (1256-1336), Ottoman Empire (1299-1922), Jalayirid Sultanate (1336-1432), Timurid Empire (1370-1507), Aq Qoyunlu (1378-1508), Qara Qoyunlu (1380-1469), Vijayapura (1490-1686), Safavid dynasty (1501-1736), Mughal Empire (1526-1858), Qutb Shahi dynasty (1512-1687), Afsharid dynasty (1736-1796), Qajar dynasty (1779 -1925), Nakhichevan Khanate (1747-1828), Erivan Khanate (1747-1828), Government of the Iranian Union (2. August 1918 - 5. December 1918) etc.
        
  In the following chronology we can observe multiple examples of the „Lion and Sun“ motif on various objects.
        
  ##### 1. The Turk Shahi period (665-850)
  <img src='img/w_01.jpg'/>

  The „Lion and Sun“ motif on various coins of the Turk Shahi period
        
  ##### 2. Seljuk period
  <img src='img/w_02.jpg'/>
        
  The „Lion and Sun“ motif on a coin from the Seljuk period (1037-1194)
        
  <img src='img/w_03.jpg'/>
        
  The „Lion and Sun“ motif in the Seljuk period
        
  <img src='img/w_04.jpg'/>
        
  The „Lion and Sun“ motif in the Sultanate of Rum
        
  <img src='img/w_05.jpg'/>
        
  Seljuk coin with the „Lion and Sun“ motif from the time of Kaykhusraw I (1240)

  ##### 3. Khwarazmian Empire (1077-1231)
  <img src='img/w_06.jpg'/>
        
  The „Lion and Sun“ motif on a coin from the time of Jalal al-Din Mangburni
  
  ##### 4. Ilkhanate period (1256-1336)
  <img src='img/w_07.jpg'/>
        
  The „Lion and Sun“ motif on coin from the Ilkhanate period
  
  <img src='img/w_08.jpg'/>
        
  The „Lion and Sun“ motif on pottery from he Ilkhanate period
  
  ##### 5. Timurid Empire (1370-1507)
  <img src='img/w_09.jpg'/>
        
  On the flag of the Timurid era Motif "Lion and Sun" (1423)

  <img src='img/w_10.jpg'/>
        
  The „Lion and Sun“ motif on the Ulugh Beg Madrasa in Samarkand, Uzbekistan

  ##### 6. Ottoman Empire (1299-1922)
  <img src='img/w_11.jpg'/>
        
  The „Lion and Sun“ motif on a commemorative badge in honor of the victory at Çanakkale
  
  ##### 7. Safavid dynasty (1501-1736)
  <img src='img/w_12.jpg'/>
        
  The „Lion and Sun“ motif on the flag from the time of the Safavid dynasty
  
  <img src='img/w_13.jpg'/>
        
  The „Lion and Sun“ motif on a flag in a portrayal of Safavid ambassador Mohammad Reza Beg’s expedition to Paris
  
  ##### 8. Mughal Empire (1526-1858)
  <img src='img/w_14.jpg' />
        
  The „Lion and Sun“ motif on the Mughal flag
  
  <img src='img/w_15.jpg' />
        
  The „Lion and Sun“ motif on another Mughal flag
  
  <img src='img/w_16.jpg' />
        
  The „Lion and Sun“ motif on a coin from the time of Mughal Emperor Jahāngīr

  <img src='img/w_17.jpg' />
        
  The „Lion and Sun“ motif on another coin from the time of Mughal Emperor Jahāngīr

  ##### 9. Qutb Shahi dynasty (1518-1687)
  <img src='img/w_18.jpg'/> 
        
  The „Lion and Sun“ motif during the Qutb Shahi dynasty
  
  ##### 10. Afsharid dynasty (1736-1796)
  <img src='img/w_19.jpg'/>
        
  The „Lion and Sun“ motif on the Afsharid flag
  
  <img src='img/w_20.jpg'/>
        
  The „Lion and Sun“ motif on the seal of Nader Shah (1746)
  
  ##### 11. Qajar dynasty (1779-1925)
  <img src='img/w_21.jpg'/>
        
  The „Lion and Sun“ motif on the Qajar flag from the time of Mohammad Shah Qajar
  
  <img src='img/w_22.jpg'/>
        
  The „Lion and Sun“ motif on the flags from the time of Fath-Ali Shah Qajar

  <img src='img/w_23.jpg'/>
        
  The „Lion and Sun“ motif on the coins from the time of Fath-Ali Shah Qajar(1830)
  
  <img src='img/w_24.jpg'/>
        
  The „Lion and Sun“ motif on the flag from the era of Mohammad Shah Qajar

 <img src='img/w_25.jpg'/>
        
  The „Lion and Sun“ motif on the flag from the era of Naser al-Din Shah Qajar and later 

  <img src='img/w_26.jpg'/>
        
  The „Lion and Sun“ motif on a stamp from the time of Mohammad Ali Shah Qajar (1906)

  <img src='img/w_27.jpg'/>
        
  The „Lion and Sun“ motif on a silver coin from the time of Ahmad Shah Qajar

  <img src='img/w_28.jpg'/>
        
  Coat of arms of the Qajar Empire
  
  <img src='img/w_29.jpg'/>
        
  The „Lion and Sun“ motif in a newspaper Vakayi-i İttifakiye during the Qajar period
  
  ##### 12. Khanates of the Qajars
  <img src='img/w_30.jpg'/>
        
  The „Lion and Sun“ motif on flags presented to the Nakhichevan Khanate (1747-1828) by Abbas Mirza

  <img src='img/w_31.jpg'/>
        
  The „Lion and Sun“ motif on flags presented to the Nakhichevan Khanate (1747-1828) by Abbas Mirza

  <img src='img/w_32.jpg'/>
        
  The „Lion and Sun“ motif on the flag of the Erivan Khanate (1747-1828)
  
  ##### 13. The administrative period of the Union of Iran (August 2, 1918 – December 5, 1918)
  <img src='img/w_33.jpg'/>
        
  The "lion and sun" motif on the flag of the Government of the Iranian Union (2 August 1918 – 5 December 1918)
  
  ##### 14. Turkmen carpets
  <img src='img/w_34.jpg'/>
        
  The „Lion and Sun“ motif on Turkmen tapestries
  
  ##### 15. Currency
  <img src='img/w_35.jpg'/>
        
  The „Lion and Sun“ motif on the currency of the Republic of Uzbekistan
    
`,
        
  "akkordeon.images.headline": "pictures"
}