<template>
  <div class="section">
    <div class="container">
      <div class="footer-wrap">
        <div class="w-container">
          <a href="https://www.facebook.com/officialbabek" target="_blank" class="w-inline-block"><img
            src="img/icons8-facebook_filled.png" loading="lazy" width="30" alt="" class="image-7"></a>
          <a href="https://www.instagram.com/officialbabek" target="_blank" class="w-inline-block"><img
            src="img/icons8-instagram_new_filled.png" loading="lazy" width="30" alt=""
            class="image-6"></a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "TheFooter"
}
</script>

<style scoped>

</style>