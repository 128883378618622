export default {
  "intro.headline": `Добро пожаловать на персональный 
  
  сайт Бабека Петра Падара (Бабек Каджар)!`,
 "intro.text": `
  `,
        
         "intro.picture1": `Документ о выходе из иранского гражданства

   `, 
    "intro.picture2": `Газетный репортаж после ограбления 13 ноября 1989 г.

   `,       
        
  "about.headline": `О Бабеке Петре Падаре (Бабек Каджар)`,
  "about.text": `
  Бабек Каджар, немецкий инвестор, является потомком иранского императора Фехт Али Шаха Каджара.
  
  Бабек Каджар, отец Рамазан Падар (1 мая 1922 года, Баку, Азербайджан - 16 января 2007 года, Берлин, Германия), а мать - Махиндохт Падар (23 сентября 1947 года, Ардебиль, Иран), был рожден в Западном Берлине 11 июня 1970 года.
  
  Из-за политических разногласий его отцу было приказано покинуть Иран и уехать в Германию после экспроприации всей его собственности в 1956 году. Ему было позволено брать с собой только 100 долларов США. Иран лишил гражданство его и его семью 9 сентября 1980 года. Таким образом, семья Падаров была среди немногих семей, изгнанных Ираном.
  
  Во время холодной войны Бабек Каджар жил в Западном Берлине как гражданин Ирана. Он принял немецкое гражданство 17 ноября 1980 года и не владеет гражданством другой страны. Бабек Петер Падар, говорящий на пяти разных языках: немецком, английском, турецком, азербайджанском и персидском, исповедует Ислам.
    
  `,

  "selfmade.headline": `Человек, которому удалось справиться, несмотря на большую семью`,
  "selfmade.text": `
  После приезда в Западный Берлин его отец  стал бизнесменом, а затем занялся инвестициями в недвижимость. В конечном итоге Рамазану Падару удалось стать успешным бизнесменом в сфере недвижимости. В то же время он подготовил своего маленького сына к тому, чтобы он имел все необходимые навыки, и внушил ему, что он должен много работать, чтобы использовать все возможности.
        
  В раннем возрасте Бабек сумел быстро начать проявлять интерес к этой сфере. Когда ему было всего 12 лет, он смог заключить свой первый договор аренды для своего отца по собственному желанию, и после этого он начал свою карьеру.
  `,

  "juwelier.headline": `Самый молодой ювелир Берлина`,
  "juwelier.text": `
  В 1989 году, когда ему было всего 18 лет, Бабек Петер Падар стал самым молодым ювелиром Берлина. Продолжая учебу в школе, он открыл ювелирный магазин и в период с 1989 по 1991 год управлял им самостоятельно. Он заключил партнерство с другим ювелиром в Берлине за год до того, как стал владельцем собственного бизнеса, и вскоре после этого распустил партнерство. 
  `,

  "wurzeln.headline": `Возвращение`,
  "wurzeln.text": `
  В период с 1994 по 2004 год Бабек Петер Падар взял на себя управление активами своего отца. Он начал инвестировать в недвижимость и холдинги в 2004 году и с тех пор работает как успешный международный инвестор. 
  `,

  // Akkordeon - Familie und Co
  "akkordeon.familie.headline": `Семья и генеалогическое древо`,
  "akkordeon.familie.text": `
  Бабек женат на Лейле Падар (фамилия до брака Тунч). Он встретил свою жену в Западном Берлине в марте 1989 года. Как третий ребёнок семьи  Вели Тунч и (10 июня 1941 г., Варна, Болгария, 1 сентября 2004 г. Чорлу, Турция) Айше Тунч (девичья фамилия Акдоган, 23 марта 1944 г., Мисинли, Турция), Лейла Падар родилась 6 октября 1972 года в Западном Берлине как гражданка Турции. С Бабек Каджаром они познакомились в ювелирном магазине Падара. Как только Бабек увидел за витринами Лейлу Тунч, которая пришла в магазин в качестве покупательницы, он решил жениться на ней. Вскоре 8 декабря 1989 года в Западном Берлине прошли свадебные церемонии. Свадьба состоялась 12 января 1990 года в бальном зале "Schloss-Saal" отеля Bristol Hotel Kempinski (Курфюрстендамм / Фазаненштрассе). 9 июня 1994 года Лейла Падар приняла немецкое гражданство.
    
  У Лейлы и Бабека Петера Падар двое детей, родившихся в Берлине: их сын Сердар-Аббас Кевин родился 18 октября 1991 года, а дочь Изабель Фатима-Сорая - 24 ноября 1998 года. Они оба изучают право и бизнес соответственно в столице Германии.
  `,

  // Akkordeon - Geschichte
  "akkordeon.geschichte.headline": `Династия Каджаров и ее место в истории`,
  "akkordeon.geschichte.text": `
  В течение 131-летнего периода Иранское плато находился под властью династий Каджаров. Они являются потомками Ченгиз-хана (1155-18 августа 1227 г.), от бозокской ветви огузских тюрков до рода Баят, и в то же время одной из низших ветвей рода Джелайир. Качары - являются потомками Хулагу Хана (1217- 8 февраля 1265), внука Чингисхана и младшего брата Хубилай Хана (23 сентября 1215-18 февраля 1294, Пекин). В последующие годы Хубилай Хан основал династию Юань и стал китайским императором. Турецкие Сельджуки принадлежат подгруппе Кынык (Kınık) из группы Учок (Üçok), также происходят от племени Огузов, Надир Шах принадлежит подгруппе Афшар (Afşar) из группы Бозок (Bozok), и Османы принадлежат подгруппе Каи (Kayı) из группы Бозок (Bozok), В конечном итоге, Каджары и Османы являются родственниками.
  
  Семь императоров из семьи Каджаров правили Ираном в период с 1794 по 1925 год.
  
  #### Императоры династии Каджаров
  
  1. Ага Мухаммед Шах Качар (правил в 1794-1797 гг.)
  2. Фет Али Шах Качар (правил в 1797-1834 гг.)
  3. Мухаммед Шах Качар (правил в 1834-1848 гг.)
  4. Насреддин Шах Качар (правил в 1848-1896 гг.)
  5. Музаферидин Шах Качар (правил в 1896-1907 гг.)
  6. Мухаммед Али Шах Качар (правил в 1907-1909 гг.)
  7. Ахмед Шах Качар (правил в 1909-1925 гг.)

  Основателем династии был Ага Мухаммед Хан Качар (позже Ага Мухаммед Шах Качар), который много лет воевал и определил нынешние границы Ирана. В 1796 году Ага Мухаммед Хан Качар стал императором Ирана и впоследствии был назван Ага Мухаммед Шахом Качаром. Столицей Ирана был Тегеран, который в то время был деревней, еще не имевшей исторической ценности.
  
  Под властью Аги Мохаммеда Шаха Каджара и его племянника, императора Фет Али Шаха Каджара, Иранское плато, который лидеры династии Зенд намеревались ликвидировать, было воссоединено. Если бы не существовало правления Каджаров, Иран полностью исчез и не был бы Ираном, каким он является сегодня.
  
   
  #### Конституционная монархия Ирана
  
  Во время правления императора Музафериддина Шаха Каджара Иран (Иран Мемалик-и Махрузеси) перешел от абсолютной монархии к конституционной монархии как первое исламское государство. Император, который был более реформатором, чем его предшественники, подписал конституцию, которая вступила в силу 30 декабря 1906 года.
        
  Император того времени Музаферидин Шах Качар принял выборы парламента (Маджлес) в 1906 году, парламент утвердил конституцию (Канун-е Асаси), которая включала основные гражданские права. В результате Иран, первое исламское государство, превратился в демократию с избираемым парламентом и конституцией.
        
  После политических изменений возросло сильное доминирующее влияние колониальных держав Великобритании и России.
        
  В 1908 году в Иране были открыты первые месторождения нефти. Хотя это великое открытие создало новые возможности для развития страны, оно привело к еще более серьезному усилению вмешательства в страну эксплуататорских держав. В результате развитие молодой демократии, которая начало проявляться в стране, было практически заблокировано.
        
  Британия поспешно обратилась к плану заставить страну капитулировать, поскольку демократия в Иране казалась препятствием для колониальных держав в использовании страны для добычи нефти.

Люди турецкого происхождения, проживающие в Иране, установили более прочное сотрудничество с Турцией, чтобы вместе противостоять колониальной власти. Усиление власти местного населения, которое они хотели использовать, напугало и оттолкнуло Британию. Колониальные державы предпочитали маленькие и слабые страны, которые им было легче контролировать. Посеяв семена раздора среди местного населения помешали этим странам объединиться, так как их было бы намного проще использовать, , превратив их в несколько маленьких и слабых стран.

Во время правления Каджара в Иране было либеральное, многокультурное и многонациональное общество без какой-либо дискриминации.

Воображаемая арийская теория, была известна в Европе уже в начале 20 века как метод разделения людей ненавистью и унижением. Великобритания обратилась к использованию древнеперсидской мифологии в качестве инструмента для раскрытия паниранизма. Между паниранизмом и культурой людей, живущих на иранском плато нет сходства или связи. Теории, подобные панарабизму, были успешно использованы при крахе Османской империи. 

По этим причинам Великобритания доверяла и решила использовать антитурецких личностей в своих политических интересах. 

Британские спецслужбы представили Резу Палани, грубого, неграмотного бывшего конюха Каджаров, в качестве преемника, который дослужился до должности командующего казаками. Англия планировала создать послушное иранское государство через Резу. 

В результате военного переворота, организованного Великобританией, Реза насилием разрушил молодую демократию в 1925 году и вынудил парламент назначить себя абсолютным правителем, оказав давление. Прозвище Пехлеви, Резе дал британский агент Ардашир Репортер. После этого его назвали Реза Пехлеви, после чего последовало организованное преследование каджаров и их союзников.

Многие высокопоставленные Каджары выбежали в зарубежные страны, ушли в подполье или были арестованы. С другой стороны, некоторые низшие Каджары под руководством императора Ахмеда Шаха Каджара сотрудничали с Резой Пехлеви в надежде на недоступные финансовые и социальные преимущества - из-за своего более низкого ранга. Император Ахмед Шах Каджар, последний законный император Ирана, который находился во Франции для лечения во время переворота, не вернулся назад. Ахмад Шах Качар никогда не признавал власть Резы Палани. 

Реза, который был фашистом и антидемократом после пленения Гитлером, перешел из Англии на противоположную сторону и сотрудничал с нацистским режимом. Во время Второй мировой войны Риза пытался поставлять нефть для немецких боевых машин.

Реза Пехлеви был свергнут Англией за поддержку Гитлера, а затем сослан в Южную Африку, где и три года спустя скончался. Его сын Мохаммед Реза Пехлеви по прозвищу «Арья-михр», что означает «Солнце арийцев», был безжалостным диктатором, как и его отец, и его правление было свергнуто народом в 1979 году, и он сбежал в Египет. Как и его отец, он умер в Африке и был похоронен там.

      
  #### От Тегерана до Берлина - изгнание его отца
        
  Из-за близости Резы Пехлеви к Гитлеру Иран был оккупирован Советским Союзом и Великобританией с внутренними конфликтами и сепаратизмом в результате фашистской диктатуры.
      
  Отец Бабека, гражданин Ирана Рамазан Падар родился 1 мая 1922 года в Баку, в Советском Азербайджане. Ранее семья Рамазана Падара переехала из Ирана в Баку, чтобы помочь наладить местную нефтяную промышленность.

  Азербайджанская Демократическая Республика была создана в 1918 году сразу после распада Российской империи. Республика, столицей которой является Баку, была признана суверенным государством в международном праве и была оккупирована и аннексирована Советским Союзом в 1920 году в нарушение международного права.

  Члены семьи были лишены всего их имущества, поскольку они были иранскими гражданами, а также аристократами, и были арестованы советской секретной службой НКВД в 1938 году в ходе операции «Плащ и кинжал» и депортированы в Иран. Там семье против их воли была присвоена произвольно выбранная фамилия Падар. Падар - это название района в Иранском Азербайджане. Когда семья заявила, что не хочет иметь это имя, им пригрозили тюремным заключением.

  Кроме того, Рамазан Падар боролся не только за суверенитет своей страны, но и против диктатуры Пехлеви в Иране. В 1950-х годах потомок Каджаров Мохаммед Мосаддык был всенародно избран премьер-министром Ирана и одним из основателей Национальной границы Ирана. Попытки Великобритании украсть иранскую нефть вылились в международный кризис и достигли своего апогея с национализацией иранской нефти премьер-министром Мохаммедом Мосаддыком.

  С операции «Аякс» в 1953 году CIA окончательно свергло правительство премьер-министра Мохаммеда Мосаддыка, избранного народом. Как обычно в результате власть режима диктатуры Пехлеви навсегда обеспечили западные спецслужбы.

  В связи с продолжающимся преследованием и вторым лишением имущества для всей семьи - на этот раз диктатурой Пехлеви - Рамазан Падар в 1956 году был сослан в город Берлин, где жил его брат, всего со 100 долларами в кармане.
  `,



"akkordeon.residenzen.headline": `Поселение, искусства и семейное имущество `,
  "akkordeon.residenzen.text": `
  Семья Династии сумела сыграть важную роль в любви многих людей к искусству и взяла под свою защиту многих людей искусства, любителей искусства. От них уцелело более 60 дворцов, корон и других драгоценных камней, а также бесценные и бесчисленные драгоценности. Ниже описаны только некоторые из них.

  #### Герб династии Каджаров
  
  Лев и солнце являются знаками на фамильном гербе Каджаров. 
  
  
  #### Императорские дворцы в Иране: культурное наследство
    
  ##### Дворец Гюлестан
  
  Правительственный дворец Каджаров был построен в Тегеране в 18-19 веках. Дворец Гюлестан с 2013 года внесен в список Всемирного наследия ЮНЕСКО. 
        
  Фундамент этого дворца был заложен во время правления Тахмасипа I, хана династии Сефевидов, как военная крепость, и он использовался в качестве официальной резиденции Каджаров во время правления Ага Мухаммед-хана и достиг пика своего значения. Архитектура и планировка дворца продолжали развиваться более поздними членами династии. Дворец сохранил свое великолепие до наших дней, его площадь составляет 4,5 гектара, и он открыт для посещения.
    
  ##### Дворцовый комплекс Саадабад
  
  Дворцовый комплекс Саадабад состоит из 18 дворцов и занимает площадь около 110 гектаров. Он использовался как летняя резиденция императоров Каджаров. Самый большой из дворцов принадлежит Белому дворцу, а самый старый - Зеленому дворцу; Также на территории комплекса находится Музей изобразительных искусств.
  `,

     // Akkordeon - Wappen
  "akkordeon.wappen.headline": `Мотив льва и солнца
  
   или символ в турецкой культуре
`, 
        
   "akkordeon.wappen.text":`
  Многие ошибочно полагают, что мотив льва и солнца на иранском флаге в период правления династии Пехлеви не имеют ничего общего с турецкой культурой. Однако при рассмотрении исторических данных ясно видно, что это не так и этот мотив принадлежит тюркской цивилизации и культуре. Мотив и символ «Лев и Солнце» часто встречаются на монетах, банкнотах, надгробиях, архитектурных произведениях, кубках, изделиях из стекла, кирпичах, флагах и других предметах и произведениях, относящихся ко многим периодам турецкой истории, включая турецкую мифологию.
  
  Мотив «Лев и Солнце» никогда нельзя отнести к персидской культуре или зороастрийской религии, он не встречается в Персеполе, городе Йезд или других центрах персидской культуры.
        
  Иранский националистический демагог Муктаба Минави предложил удалить мотив льва и солнца из иранской культуры, хотя он знал, что он имеет турецкое происхождение*.
   
  *(Источник Муктаба Миневи, История и культура, Печать: 3, Ширкет-и Сахами-йи Интишарата-и Харезми, Тегеран, 1369, с. 352, 359, 374, 378.)
        
  Названия исторических тюркских государств, использующих этот мотив: Кушанское царство (30-375 гг.), Тюркшахи (665-850 гг.), Газневиды (963-1186 гг.), Великие сельджуки (1037-1157 гг.), Анатолийские сельджуки (1077-1308 гг.), Хорезмшахи (1077-1231 гг.), Египетские мамлюки (1250-1517 гг.), Эльханиды (1256-1336 гг.), 	Османская империя (1299-1922 гг.), Джалаиры (1340-1432 гг.), Тимуриды (1370-1507 гг.), Аккоюнлу (1378-1508 гг.), Каракоюнлу (1380-1469 гг.), Биджапурский султанат (1490-1686), Сефевиды (1501-1736 гг.), Бабуры (1526-1858 гг.), Кутбшахи (1518-1687 гг.), Афшары (1736-1796 гг.), Гаджары (1779-1925 гг.), Нахчыванское ханство (1747-1828 гг.), Раванское ханство (1747-1828 гг.), Период союзной власти в Иране (2 августа 1918 г. — 5 декабря 1918 г.) и так далее. 
        
  Ниже приведены примеры мотива или символа льва и солнца в хронологическом порядке.
        
  ##### 1. Период Тюркшахов (665-850 гг.)
  <img src='img/w_01.jpg'/>

  Символ льва и солнца на монетах туркшаховского периода.
        
  ##### 2. Период Сельджуков (1037-1157 гг.)
  <img src='img/w_02.jpg'/>
        
  Символ льва и солнца на сельджукских монетах (1037-1194 гг.)
        
  <img src='img/w_03.jpg'/>
        
  Символ льва и солнца в ступе периода сельджуков
        
  <img src='img/w_04.jpg'/>
        
  Символ лев и солнца в произведениях анатолийских сельджуков
        
  <img src='img/w_05.jpg'/>
        
  На монетах сельджукского периода Гиясаддина Кейхосрова Символ льва и солнца (1240)


  ##### 3. Период Хорезмшахов (1077-1231 гг)
  <img src='img/w_06.jpg'/>
        
  На монетах периода Джалаледдина Хорезмшаха Менгуберти Символ льва и солнца
  
  ##### 4. Эльханидский период (1256-1336 гг.)
  <img src='img/w_07.jpg'/>
        
  Символ льва и солнца на монетах Эльханидов
  
  <img src='img/w_08.jpg'/>
        
  Символ льва и солнца в керамических изделиях эльханидского периода.
  
  ##### 5. Период Тимуридов (1370-1507 гг.)
  <img src='img/w_09.jpg'/>
        
  Символ льва и солнца на флаге периода Тимуридов (1423 г.)
  
  <img src='img/w_10.jpg'/>
        
  Символ льва и солнца в медресе Улуг-бея

  ##### 6. Период Османской империи (1299-1922 гг.) 
  <img src='img/w_11.jpg'/>
        
  Символ льва и солнца на памятном значке, посвященном победе при Чанаккале.
  
  ##### 7. Сефевидский период (1501-1736 гг.)
  <img src='img/w_12.jpg'/>
        
  Символ Льва и Солнца на флаге периода Сефевидов 
  
  <img src='img/w_13.jpg'/>
        
  Флаг Сефевидов на изображении визита посланника Сефевидов Мохаммада Реза Бея в Париж, символ Льва и Солнца.
  
  ##### 8. Период Бабуров (1526-1858 гг.)
  <img src='img/w_14.jpg' />
        
  Символ Льва и Солнца на флаге Бабуров
  
  <img src='img/w_15.jpg' />
        
  Еще один флаг Бабуров и символ Льва и Солнца.
  
  <img src='img/w_16.jpg' />
        
  Символ Льва и Солнца на монетах периода Бабуров, период Джахангир Шаха

  <img src='img/w_17.jpg' />
        
  Еще один символ Льва и Солнца на монетах периода Бабуров, период Джахангир Шаха

  ##### 9. Период Кутбшахов (1518-1687 гг.)
  <img src='img/w_18.jpg'/>
        
  Символ Льва и Солнца периода Кутбшахов
  
  ##### 10. Афшарский период (1736-1796 гг.)
  <img src='img/w_19.jpg'/>
        
  Символ Льва и Солнца на флаге Афшаров
  
  <img src='img/w_20.jpg'/>
        
  В каганате Надир-шаха Афшара Символ льва и солнца (1746 г.)

  
  ##### 11. Гаджарский период (1779-1925 гг.)
  <img src='img/w_21.jpg'/>
        
  Символ Льва и Солнца на флаге периода Ага Мохаммад Шаха Гаджара.
  
  <img src='img/w_22.jpg'/>
        
  Символ льва и солнца на флагах периода Фатали Шаха Гаджара

  <img src='img/w_23.jpg'/>
        
  Символ льва и солнца на монетах периода Фатали Шаха Гаджара (1830 г.)
  
  <img src='img/w_24.jpg'/>
        
  Символ Льва и Солнца на флаге времен Мухаммад Шаха Гаджара

 <img src='img/w_25.jpg'/>
        
  Символ Льва и Солнца на флаге Насреддина Шаха Гаджара, а затем и на флаге Гаджаров

  <img src='img/w_26.jpg'/>
        
  Символ льва и солнца на почтовой марке периода Мухаммеда Али Шаха Гаджара (1906 г.)

  <img src='img/w_27.jpg'/>
        
  На серебряных монетах периода Ахмед Шаха Каджара Символ льва и солнца

  <img src='img/w_28.jpg'/>
        
  Символ льва и солнца на гербе Гаджаров
  
  <img src='img/w_29.jpg'/>
        
  Газета Vakayi-yi Ittifaqiya о гаджарском периоде опубликован символ льва и солнца 

  ##### 12. Гаджарские ханства
  <img src='img/w_30.jpg'/>
        
  Символ льва и солнца на флагах, подаренных Аббасом Мирзой Гаджаром Нахчыванскому ханству (1747-1828 гг.)

  <img src='img/w_31.jpg'/>
        
  Символ льва и солнца на флагах, подаренных Аббасом Мирзой Гаджаром Нахчыванскому ханству (1747-1828 гг.)

  <img src='img/w_32.jpg'/>
        
  Символ льва и солнца на флаге Раванского ханства (1747-1828 гг.)
  
  ##### 13. Период союзной власти в Иране (2 августа 1918 г. — 5 декабря 1918 г.)
  <img src='img/w_33.jpg'/>
        
  Символ льва и солнца на флаге союзного правительство Ирана (2 августа 1918 г. - 5 декабря 1918 г.)
  
  ##### 14. Турецкие ковры
  <img src='img/w_34.jpg'/>
        
  Символ льва и солнца на туркменском ковре
  
  ##### 15. Банкноты
  <img src='img/w_35.jpg'/>
        
  Символ Льва и Солнца на банкнотах Республики Узбекистан, одного из турецких государств
    
`,

  "akkordeon.images.headline": "Фотографии"
}