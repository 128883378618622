<template>
  <div :style="textStyle">
    <the-menu/>
    <router-view/>
    <the-footer/>
  </div>
</template>

<script>
import TheMenu from "@/components/TheMenu";
import TheFooter from "@/components/TheFooter";
import {localGetter} from "./config/CurrentLocale";

export default {
  name: 'App',
  components: {TheFooter, TheMenu},
  computed: {
    ...localGetter,
    textStyle() {
      return {
        direction: this.isRTL ? 'rtl' : 'ltr'
      }
    }
  },
  provide() {
    return {
      t: localGetter.t
    }
  }
}
</script>

<style>

</style>
