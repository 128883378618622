export default {
  "intro.headline": `Babək Peter Padarın (Babək Qacarın) 
  
  Şəxsi Websaytına xoş gəlmisiniz! `,

 "intro.text": `
  `,

  "intro.picture1": `İran Vətəndaşlığından Çıxışla bağlı sənəd

   `, 
    "intro.picture2": `13 noyabr 1989-cu il soyğundan sonra qəzet reportajı

   `,       

  "about.headline": `Babək Peter Padar Haqqında `,
  "about.text": `
  Alman sərmayəçi Babək Peter Padar, İran İmparatoru Fətəli Şah Qacarın soyundandır. 

  Atası Ramazan Padar (1 may 1922, Bakı, Azərbaycan - 16 yanvar 2007, Berlin, Almaniya), anası isə Mahindokht Padar (23 sentyabr 1947, Ərdəbil, İran) olan Babək Peter Padar 11 iyun 1970-cı ildə qərbi Berlində dünyaya göz açmışdır.

  Siyasi anlaşmazlığa görə 1956-cı ildə bütün mülkləri müsadirə olunan atasından İranı tərk edərək Almaniyaya mühacirət etməsi tələb olunmuşdur. Özüylə yalnız və yalnız 100 Amerika dolları aparmağına icazə verilmişdir. Iran höküməti, onu və ailəsini 9 sentyabr 1980-ci ildə vətəndaşlıqdan məhrum etmişdir. Bununla da Padar ailəsi İran tərəfindən vətəndaşlıqdan məhrum olunan az saydakı ailələrdən birinə çevrilmişdir.

  Soyuq müharibə ərzində Babək Qacar qərbi Berlində İran vətəndaşı kimi yaşamışdır. 1980-ci ilin 17 noyabr tarixində o, Alman vətəndaşlığını qəbul etmişdir. Başqa bir ölkədə vətəndaşlığı yoxdur. Alman, İngilis, Türk, Azərbaycan Türkcəsi, Fars dilləri olmaqla 5 fərqli dildə danışan  Babək Peter Padar İslam dini inancına sahibdir.
    
  `,

  "selfmade.headline": `Böyük ailə sahibi olmasına baxmayaraq özünü inkişaf etdirməyə nail olan adam  `,
  "selfmade.text": `
  Qərbi Berlinə çatan kimi atası biznesmen olaraq fəaliyyətə başlamış və bir müddətdən sonra daşınmaz əmlak investisiyalarına yönəlmişdir. Sonda isə Ramazan Padar daşınmaz əmlak bazarında uğurlu bir biznesmenə çevrilmişdir. Eyni vaxtda gənc oğlunu da bu iş üçün hazırlamış, ona, bütün fürsətləri düzgün qiymətləndirmək üçün çox çalışmalı olduğunu aşılamışdır.

  Babək, bu sahəyə marağı olduğundan erkən yaşlarda işə qoyulmuşdur. Hələ 12 yaşlı uşaq ikən atası üçün öz istəyi ilə ilk kirə müqaviləsini hazırlamış, beləcə gələcək kariyerasına aparan yola ilk addımını atmışdır.
`,

  "juwelier.headline": `Berlinin ən gənc zərgər dükanı sahibi`,
  "juwelier.text": `
  1989-cu ildə, hələ 18 yaşı olanda Babək, Berlindəki zərgər dükanı sahibləri arasında ən gənci idi. Məktəbdə oxuya-oxuya bir qızıl mağazası açmış, 1989-1991-ci illər ərzində bütün işləri təkcə idarə etmişdir. Öz qızıl mağazasını açmazdan öncə Berlin şəhərində yerləşən başqa bir zərgər mağazasına şərik olmuş, qısa müddət sonra ortaqlığa son vermişdir.
  `,

  "wurzeln.headline": `Köklərinə Dönüş`,
  "wurzeln.text": `
  1994-2004-cü illər arasında Babək, atasının əmlakının idarəsini öz üzərinə götürmüşdür. 2004-cü ildə daşınmaz əmlak və holdinqlərə investisiya qoymağa başlamış, həmin vaxtdan bəri beynəlxalq arenada uğurlu bir sərmayəçi kimi fəaliyyətini davam etdirməkdədir.
  `,

  // Akkordeon - Familie und Co
  "akkordeon.familie.headline": `Ailə və Soy Ağacı `,
  "akkordeon.familie.text": `
  Babək, Leyla Padar (evlənməzdən öncəki soyadı Tunç) ilə evlidir. Həyat yoldaşı ilə 1989-cu ilin mart ayında Berlində tanış olublar. Vəli Tunç (10 iyun 1941, Varna, Bolqarıstan- 1 sentyabr 2004, Çorlu, Türkiyə) və Ayşe Tunç (evlənməzdən öncəki soyadı Akdoğan, 23 mart 1944, Misinli, Türkiyə) ailəsinin üçüncü övladı olaraq Leyla Padar 6 oktyabr 1972-ci ildə qərbi Berlində Türk vətəndaşı kimi dünyaya gəlmişdir. Babək Qacar ilə onun qızıl dükanında tanış olublar. Babək, mağazaya müştəri kimi gələn Leyla Tunçu vitrinlərin arxasında görən kimi onunla evlənməyə qərar vermişdir. Bir müddət sonra 1989-cu ilin 8 dekabr tarixində qərbi Berlində nikah baş tutmuşdur. Toy mərasimi isə 1990-cı ilin 12 yanvar günü Bristol Hotel Kempinskinin (Kurfürstendamm / Fasanenstrasse) "Schloss-Saal" Şənlik Zalında keçirilmişdir. 9 İyun 1994 tarixində Leyla Padar Almaniya vətəndaşlığını qəbul etmişdir.

  Leyla və Babək cütlüyünün hər iki övladı da Berlində dünyaya gəlib: oğulları Sərdar-Abbas Kevin 18 oktyabr 1991-ci ildə, qızları İsabelle Fatima-Soraya isə 24 noyabr 1998-ci ildə anadan olub. İkisi də Almaniyanın paytaxtında müvafiq olaraq Hüquq və Biznes sahəsində təhsil alırlar.  
  `,

  // Akkordeon - Geschichte
  "akkordeon.geschichte.headline": `Qacar Xanədanlığı və Tarixdəki Mövqeyi`,
  "akkordeon.geschichte.text": `
  131 il boyunca İran yaylası Qacar xanədanları tərəfindən idarə olunmuşdur. Onlar, Oğuz türklərinin Bozox qolundan Bayat boyuna, həm də Cəlayir boyunun alt qollarından biri olan Qacar oymağı Çingiz xanın (1155- 18 avqust, 1227) soyundan gəlirlər. Qacarlar Çingizxanın nəvəsi və Qubilay xanın (23 sentyabr 1215-18 fevral 1294, Pekin) soyundandır. Növbəti illərdə Qubilay xan Yuan xanədanlığını quraraq Çin imperatoru olmuşdur. Türk Səlcuqlar, Oğuz türklərinin Üçox qolundan Qınıq, Nadir Şah, Bozoxların Əfşar və Osmanlılar isə Bozoxların Qayı boyundandır. Yəni, qısaca, Qacarlar və Osmanlılar bir-biriylə qohumdur. Qacar ailəsinin yeddi xaqanı 1794-1925-ci illər ərzində İranda hökm sürüblər.
  
  #### Qacar xanədanlığının xaqanları (imparatorları)
  
  1. Ağa Məhəmməd Şah Qacar (1794-1797-ci illər ərzində hakimiyyətdə olub)
  2. Fətəli Şah Qacar (1797-1834-cü illər ərzində hakimiyyətdə olub)
  3. Məhəmməd Şah Qacar (1834 -1848-ci illər ərzində hakimiyyətdə olub)
  4. Nəsrəddin Şah Qacar (1848-1896-cı illər ərzində hakimiyyətdə olub)
  5. Muzaffərəddin Şah Qacar (1896-1907-ci illər ərzində hakimiyyətdə olub)
  6. Məhəmməd Əli Şah Qacar (1907-1909-cu illər ərzində hakimiyyətdə olub)
  7. Əhməd Şah Qacar (1909 -1925-ci illər ərzində hakimiyyətdə olub)

  
  Xanədanlığın banisi, uzun illər boyu döyüşən, İranın bu günkü sərhədlərini müəyyənləşdirən Ağa Məhəmməd Xan Qacar (daha sonra Ağa Məhəmməd Şah Qacar) olmuşdur. 1796-cı ildə Ağa Məhəmməd Xan Qacar İranın imperatoru olmuş və Ağa Məhəmməd Şah Qacar adını almışdır. İranın paytaxtı isə Tehran olmuşdur, o vaxtlar hələ Tehran tarixi dəyəri olmayan bir kənd idi.

  Ağa Məhəmməd Şah Qacarın və qardaşı oğlu olan xələfi, imperator Fətəli Şah Qacarın hökmdar olduğu vaxtda Zənd xanədanlığının liderlərinin parçalamaq istədiyi İran yaylası yenidən birləşdirilmişdir. Qacarlar hakimiyyəti olmasaydı İran XVIII. əsrdə tamamilə yox olacaq və yer üzündə var olan bu günki İran mövcud olmayacaqdı.

  
  #### İranın Konstitusiyalı Monarxiyası
  
  Xaqan Muzaffərəddin Şah Qacar dövründə İran Qorunmuş Məmləkətləri (İran Memalik-i Mahrusesi), ilk İslam dövləti olaraq mütləq monarxiyadan konstitusiyalı monarxiyaya keçid etmişdir. 

  Sələflərinə nisbətən daha reformçu olan xaqan, 30 dekabr 1906-cı ildə qüvvəyə minən konstitusiyanı imzalamışdır.

  Dövrün xaqanı Muzaffərəddin Şah Qacar 1906-cı ildə parlamentin (Macles) seçilməsini qəbul etmiş, parlament isə öz növbəsində vətəndaş hüqüqlarını ehtiva edən konstitusiyanı (Qanun-e Əsasi) təsdiq etmişdir. Nəticə etibarilə ilk İslam dövləti olan İran, seçilmiş bir parlamenti və kontitusiyası olan konstitusiyalı monarxiyaya çevrilmişdir.

  Siyasi dəyişikliklərdən sonra Böyük Britaniya və Rusiyanın müstəmləkəçi qüvvələrinin güclü təsiri artmağa başlamışdır.

  1908-ci ildə İranda ilk neft yataqları kəşf olunmuşdur. Bu böyük kəşf ölkənin inkişafı üçün yeni fürsətlər yaratsa da müstəmləkəçi güclərin ölkəyə müdaxilələrinin daha ciddi hal almasına və artmasına səbəb olmuşdur. Bunun nəticəsi kimi ölkədə yeni başlayan gənc demokratiyanın inkişafı məneələrlə üzləşməyə başlamışdır.

  İrandakı gənc demokratiya müstəmləkəçi güclərin ölkədən nefti üçün sui-istifadə etməsinə mane olacağından İngiltərə ölkəni təslim olmağa məcbur etmək üçün bir plan hazırlamağa başlamışdır.

  İranda yaşayan Türk mənşəli xalq müstəmləkəçilərə qarşı birlikdə mübarizə aparmaq üçün Türkiyə ilə müttəfiq olmuşdur. Müstəmləkə kimi görmək istədiləri bölgənin xalqının güclənən hakimiyyəti Britaniyanı qorxutmuş və beləcə islam ölkələrinin güclənməsinə müxtəlif yollarla əngəl olunmuşdur. Müstəmləkəçi ölkələr daha rahat idarə edə biləcəkləri kiçik və zəif dövlətləri daha üstün tutmuşdur. Yerli xalq arasında anlaşmazlıq toxumu səpməklə onları bir neçə kiçik və zəif milli dövlətə çevirərək idarə etmək asan olduğu üçün Müsəlman ölkələrin yeni bir imperiyasının qurulması mümkün olan bütün variantlarla əngəllənmişdir.

  Qacarlar dövründə İranda, ayrıseçkilikdən uzaq, liberal, multikultural və çox irqli bir cəmiyyət mövcud olmuşdur.

  Xəyali Aryan teoriyası, XX. əsrin əvvəllərində Avropada insanları nifrətlə, xor görməklə parçalamaq üsulu kimi hamıya məlum idi. Britaniya, Paniranizmi əmələ gətirmək üçün antik bu yerlərin mitologiyasından vasitə kimi istifadə etmişdir. Paniranizmi ilə İran yaylasında yaşayan insanların adət-ənənələri və mədəni irsi arasında heç bir bənzərlik, yaxud əlaqə yoxdur. Panərəbizmə oxşar nəzəriyyələr Osmanlı imperiyasının süquta yetirilməsində uğurla tədbiq olunmuşdur. 

  Buna görə də İngiltərə öz mənafei üçün təkcə Türk əleyhdarı şəxs və ekollara güvənmiş və onlardan istifadə etməyə başlamışdır.

  İngilis agentləri Qazak Birliyinin komandiri vəzifəsinə yüksələn, kobud, oxumağı, yazmağı bacarmayan və Qacarların köhnə mehtəri olan Rza Palanini xələf kimi səhnəyə çıxarmışdılar. İngiltərə, Rzanın səyləri sayəsində itaətkar İran dövləti qurmağı planlaşdırırdı. 

  İngiltərənin hazırladığı hərbi çevrilişlə Rza 1925-ci ildə gənc demokratiyanı şiddət tədbiq etməklə yerlə yeksan etmiş, təzyiq göstərərək parlamenti, onu mütləq hökmdar təyin etməyə məcbur etmişdir. Pəhləvi ləqəbi Rzaya, Britaniya agenti Ərdəşir Reporter tərəfindən verilmişdir. Bundan sonra Rza, Pəhləvi adını almış, bununla da Qacarlara və müttəfiqlərinə qarşı silsilə zülmlər başlamışdır.

  Bir çox yüksək rütbəli Qacar xarici ölkəyə qaçmış, yerin altına girmiş, yaxud da həbs olunmuşdur. Digər tərəfdə aşağı rütbəli Qacarlar İmperator Əhməd Şah Qacar liderliyində, rütbələri aşağı olduğu üçün, əldə edə bilməyəcəkləri maddi və sosial üstünlükləri qazanmaq ümidiylə Rza Pəhləvi ilə müttəfiq olmuşlardır. Çevriliş vaxtı tibbi müalicə üçün Fransada olan İranın son rəsmi imperatoru Əhməd Şah Qacar isə bir daha geri qayıtmamışdır. Rza Palaninin hakimiyyəti heç vaxt Əhməd Şah Qacar tərəfindən tanınmamışdır.

  Hitlerin güclənməsiylə faşist və anti-demokrat olan Rza, əks tərəfə keçmiş, Nazi rejimi ilə müttəfiq olmuşdur. II Dünya Müharibəsi boyunca Alman döyüş qurğularını neftlə təmin etmək üçün çalışmışdır.

  Rza Pəhləvi Hitleri dəstəklədiyi üçün İngiltərə tərəfindən devrilmiş və Cənubi Afrikaya sürgün edilmişdir. Rza üç il sonra orada vəfat etmişdir.  Özünə “Aryanların İşığı” mənasını verən “Arya-mihr” ləqəbini götürən oğlu Məhəmməd Rıza Pəhləvi də atası kimi zalım diktator olmuş, 1979-cu ildə xalq tərəfindən devrilmiş, Misirə qaçmışdır. Atası kimi o da Afrikada vəfat etmiş və dəfn olunmuşdur.


  #### Tehrandan Berlinə- Atasının Sürgünü
        
  Rza Pəhləvinin Hitlerə yaxınlaşmasıyıla faşist diktaturaya çevrilən İran, daxili çaxnaşmalar və anlaşmazlıqlar nəticəsində SSSR və İngiltərə tərəfindən işğal olunmuşdur.
      
  Babəkin atası, İran vətəndaşı Ramazan Padar, 1 may 1922-ci ildə Sovet Azərbaycanının Bakı şəhərində anadan olmuşdur. Ondan öncə Ramazanın ailəsi, yerli neft sənayesinin qurulmasına yardım məqsədiylə İrandan Bakıya köçmüşdür.

  1918-ci ildə Rusiya İmperiyası dağılan kimi Azərbaycan Demokratik Cumhuriyyəti qurulmuşdur. Paytaxtı Bakı olan cumhuriyyət, beynəlxalq hüquqda demokratik bir dövlət kimi tanınmış və 1920-ci ildə SSSR tərəfinfən beynəlxaq hüquqa zidd olaraq işğal olunmuşdur.

  Ailə üzvləri həm aristokrat, həm də İran vətəndaşı olduqları üçün bütün mülklərindən məhrum edilmiş, 1938-ci ildə SSSR Xalq Daxili İşlər Komissarlığı (NKVD) tərəfindən “Plaş və Xəncər” əməliyyatı ilə həbs olunaraq İrana sürgün edilmişdilər. Orada ailəyə, öz razılıqları olmadan Padar soyadı verilmişdir. Padar İran Azərbaycanındakı bir bölgənin adıdır. Ailə bu soyadı istəmədiyini bildirsə də həbslə təhdid olunmuşdular.

  Bundan əlavə Ramazan Padar, İranda təkcə öz ölkəsinin birliyi üçün yox, həm də Pəhləvi diktaturasına qarşı da mübarizə aparmışdır. 1950-ci illərdə Qacae nəslindən olan Məhəmməd Musaddıq xalq tərəfindən İranın baş naziri seçilmiş və İranın milli sərhədlərini (Cebhe-yi Milli) müəyyən etmişdir. İngiltərənin İran neftini oğurlamaq cəhdi beynəlxalq krisizə səbəb olmuş və İran neftinin Baş Nazir Məhəmməd Musaddıq tərəfindən milliləşdirilməsiylə gərginlik artmışdır.

  1953-cü ildə Ajax əməliyyatı ilə CIA, xalq tərəfindən seçilmiş Baş Nazir Məhəmməd Musaddıqı devirmişdir. Beləcə qərbin gizli gücləri tərəfindən diktator Pəhləvi rejiminin hakimiyyətinin davamlılığı təmin olunmuşdur.

  Davam edən zülm və ailənin mülklərinin ikinci dəfə müsadirə edilməsinin ardından, bu dəfə də Pəhləvi diktaturasının əliylə, Ramazan Padar, cibində cəmi 100 dollarla 1956-cı ildə böyük qardaşının yaşadığı Berlin şəhərinə sürgün edilmişdir.
  `,

  "akkordeon.residenzen.headline": `Məskunlaşma, Sənət və Ailə Mirası`,
  "akkordeon.residenzen.text": `
  Xanədan ailəsi bir çox insana sənəti sevdirmiş, bir çox sənətçini və sənət vurğununu himayəsinə götürmüşdür. Onlardan qalan 60-dan çox saray, tac və digər dəyərli daşlar, sayı-hesabı bilinməyən mücəvhərlər, xəzinələr günümüzə qədər gəlib çıxmışdır. Aşağıda sadalananlar bunların sadəcə bir hissəsidir:

  #### Qacar Xanədanlığının gerbi
  
  Aslan və Günəş Qacar gerbinin üzərindəki simvollardır.
  
  
  #### İrandakı Xaqan (İmperator) Sarayları: Mədəni Miras
    
  ##### Gülistan Sarayı
  
  Qacar hakimiyyətinin Tehrandakı sarayı olan Gülistan Sarayının inşası XIX. əsrin əvvəllərində başa çatmışdır. Gülistan Sarayı 2013-cü ildə UNESCO-nun Dünya Mirası siyahısına daxil edilmişdir.

  Bu sarayın təməlləri hərbi qala olaraq Səfəvi xanədanlığının xaqanı I. Təhmasib zamanında atılmış, Ağa Məhəmməd Xan dövründə isə Qacarların rəsmi iqamətgahı olaraq həqiqi dəyərinə çatmışdır. Sonrakı xanədan üzvləri də sarayın memari quruluşunu, nizamını inkişaf etdirməyə davam etmişlərdir. Sarayın möhtəşəmliyi günümüzə qədər gəlib çata bilmişdir, Gülistan sarayı 4,5 hektarlıq ərazidə yerləşir və xalqa açıqdır.

    
  ##### Saadabad Saray Kompleksi
  
  Saadabad Saray Kompleksi, 18 saraydan ibarətdir və təxminən 110 hektarlıq bir ərazini əhatə edir. Qacar xaqanları tərəfindən yaylaq kimi istifadə olunmuşdur. Sarayların ən böyüyü Bəyaz Saray və ən qədimi Yaşıl Saraydır. Bundan əlavə kompleks daxilində İncisənət Muzeyi də mövcuddur.
  `,

 // Akkordeon - Wappen
  "akkordeon.wappen.headline": `Aslan və Günəş motivi 
  
   yaxud Türk mədəniyyətindəki simvol
`, 
        
   "akkordeon.wappen.text":`
  Bir çoxları yanlış olaraq hesab edir ki, Pəhləvi dövründə İran bayrağında olan Aslan və günəş motivinin Türk mədəniyyətiylə əlaqəsi yoxdur. Ancaq tarixi faktlara nəzər yetirəndə bu fərziyyənin özünü doğrultmadığını və bu motivin Türk mədəniyyəti və incəsənətinə aid olduğunu aydın görmək olar. Aslan və günəş motivinə, simvoluna Türk mitologiyasında, dünyanın müxtəlif yerlərində yaşayan Türk xalqlarının tarixinin bir çox dövrlərindəki sikkə, məzar daşı, memari abidə, qədəh, şüşə, tuğ, bayraq və digər əşyaların, əsərlərin üzərində tez-tez rast gəlmək olur. 

  Aslan və günəş motivi heç bir zaman Fars mədəniyyətinə, yaxud da Zərdüşt dininə aid edilməyib, o, Persopolisdə, Yezd şəhərində və ya Fars mədəniyyətinin digər mərkəzlərində aşkarlanmayıb.
        
  Əslində, Aslan və günəş motivinin Türk mənşəli olduğunu bilən fars millətçisi demaqoq Müctəba Minəvi bu simvolun İran mədəniyyətindən çıxarılması ilə bağlı fikir irəli sürüb.*
   
  * (Muctabâ Mînevî, Tarix və Fərhəng, Çâp: 3, Şirket-i Sahâmî-yi İntişârât-ı Hârezmî, Tehran, h. ş. 1369, s. 352, 359, 374, 378.)
        
  Bu motivdən istifadə edən tarixi Türk dövlətlərinin adları: Quşanlılar (30-375), Türkşahilər (665-850), Qaznəlilər (963-1186), Böyük Səlcuqlar (1037-1157), Anadolu Səlcuqları (1077-1308), Xarəzmşahlar (1077-1231), Misir Məmlükləri (1250-1517), Elxanilər (1256-1336), Osmanlılar (1299-1922), Cəlayirlər (1340-1432), Teymurilər (1370-1507), Ağqoyunlar (1378-1508), Qaraqoyunlar (1380-1469), Adilşahilər (1490-1686), Səfəvilər (1501-1736), Babürlər (1526-1858), Qütbşahilər (1518-1687), Əfşarlar (1736-1796), Qacarlar (1779-1925), Naxçıvan xanlığı (1747-1828), Rəvan xanlığı (1747-1828), İran İttihad Hakimiyyəti Dövrü (2 avqust 1918-5 dekabr 1918) və s. Aşağıda xronoloji ardıcıllıq nəzərə alınaraq Aslan və günəş motivi yaxud simvolunun yer aldığı nümunələr təqdim olunmuşdur.
        
  Kuschana Imperium (30-375), Turk-Schahi-Dynastie (665-850), Ghaznawiden-Dynastie (963-1186), Seldschuken-Reich (1037-1194), Reich der Rum-Seldschuken (1077-1307), Choresm-Schah-Dynastie (1077-1231), ägyptische Mamluken (1250-1517), Ilchane (1256-1336), Osmanen (1299-1922), Dschalairiden (1336-1432), Timuriden (1370-1507), Aq Qoyunlu (1378-1508), Qara Qoyunlu (1380-1469), Vijayapura (1490-1686), Safawiden (1501-1736), Mogulreich (1526-1858), Qutb-Schāhī-Sultanat (1512-1687), Afschariden (1736-1796), Kadscharen (1779 -1925), Khanat Nachitschewan (1747-1828), Khanat Jerewan (1747-1828), Regierung der Iranischen Union (2. August 1918 - 5. Dezember 1918) usw.
        
   ##### 1. Türkşahilər Dövrü (665-850) 
  <img src='img/w_01.jpg'/>

  Türkşahilər dövrünə aid sikkələrdə Aslan və günəş simvolu
        
  ##### 2. . Səlcuqlar Dövrü (XI-XIV) 
  <img src='img/w_02.jpg'/>
        
  Səlcuqlar dövrünə aid sikkələrdə (1037-1194) Aslan və günəş simvolu
        
  <img src='img/w_03.jpg'/>
        
  Səlcuqlar dövrünə aid həvəngdəstədə Aslan və günəş simvolu
        
  <img src='img/w_04.jpg'/>
        
  Anadolu Səlcuqları dövrünə aid əsərlərdə Aslan və günəş simvolu
        
  <img src='img/w_05.jpg'/>
        
  Səlcuqlu Qıyasəddin Keyxosrov dövrünə aid sikkələrdə Aslan və günəş simvolu (1240)

  ##### 3. Xarəzmşahlar Dövrü (1077-1231)
  <img src='img/w_06.jpg'/>
        
  Cəlaləddin Xarəzmşah Mengübərti dövrünə aid sikkələrdə Aslan və günəş simvolu
  
  ##### 4. Elxanilər Dövrü (1256-1336)
  <img src='img/w_07.jpg'/>
        
  Elxanilər dövrünə aid sikkələrdə Aslan və günəş simvolu
  
  <img src='img/w_08.jpg'/>
        
  Elxanilər dövrünə aid keramik əsərlərdə Aslan və günəş simvolu
  
  ##### 5. Teymurilər Dövrü (1370-1507)
  <img src='img/w_09.jpg'/>
        
  Teymurilər dövrünə aid bayraqda, tuğda Aslan və günəş simvolu  (1423)
  
  <img src='img/w_10.jpg'/>
        
  Uluğ Bəy mədrəsəsində Aslan və günəş simvolu

  ##### 6. Osmanlılar Dövrü (1299-1922)
  <img src='img/w_11.jpg'/>
        
  Çanaqqala zəfəri ilə bağlı xatirə rozetində Aslan və günəş simvolu 
  
  ##### 7. Səfəvilər Dövrü (1501-1736)
  <img src='img/w_12.jpg'/>
        
  Səfəvilər dövrünə aid bayraqda Aslan və günəş simvolu 
  
  <img src='img/w_13.jpg'/>
        
  Səfəvi elçisi Məhəmməd Rza bəyin Paris səfərini əks etdirən rəsmindəki Səfəvi bayrağında, tuğda yer alan Aslan və günəş simvolu
  
  ##### 8. Babürlər Dövrü (1526-1858)
  <img src='img/w_14.jpg' />
        
  Babürlər bayrağındakı Aslan və günəş simvolu
  
  <img src='img/w_15.jpg' />
        
  Digər bir Babür bayrağındakı Aslan və günəş simvolu
  
  <img src='img/w_16.jpg' />
        
  Babürlü Cahangir Şah dövrünə aid sikkələrdə Aslan və günəş simvolu 

  <img src='img/w_17.jpg' />
        
  Babürlü Cahangir Şah dövrünə aid digər sikkələrdə Aslan və günəş simvolu 

  ##### 9. Qütbşahilər Dövrü (1518-1687)
  <img src='img/w_18.jpg'/>
        
  Qütbşahilərdə Aslan və günəş simvolu
  
  ##### 10. Əfşarlar Dövrü (1736-1796)
  <img src='img/w_19.jpg'/>
        
  Əfşarlar bayrağındakı Aslan və günəş simvolu 
  
  <img src='img/w_20.jpg'/>
        
  Nadir Şah Əfşarın xaqanlıq damğasında Aslan və günəş simvolu (1746)
  
  ##### 11. Qacarlar Dövrü (1779-1925)
  <img src='img/w_21.jpg'/>
        
  Ağa Məhəmməd Şah Qacar dövrünə aid bayraqdakı Aslan və günəş simvolu
  
  <img src='img/w_22.jpg'/>
        
  Fətəli Şah Qacar dövrünə aid bayraqlarda Aslan və günəş simvolu

  <img src='img/w_23.jpg'/>
        
  Fətəli Şah Qacar dövrünə aid sikkələrdə (1830)  Aslan və günəş simvolu 
  
  <img src='img/w_24.jpg'/>
        
  Məhəmməd Şah Qacar dövrünə aid bayraqda Aslan və günəş simvolu 

 <img src='img/w_25.jpg'/>
        
  Nəsrəddin Şah Qacar dövründə və sonra Qacar dövlətinin bayrağında yer alan Aslan və günəş simvolu 

  <img src='img/w_26.jpg'/>
        
  Məhəmməd Əli Şah Qacar dövrünə aid məktub markasında Aslan və günəş simvolu (1906)

  <img src='img/w_27.jpg'/>
        
  Əhməd Şah Qacar dövrünə aid gümüş sikkələrdə Aslan və günəş simvolu

  <img src='img/w_28.jpg'/>
        
  Qacar dövlətinin gerbində Aslan və günəş simvolu
  
  <img src='img/w_29.jpg'/>
        
  Qacarlar dövrünə aid Vakayi-yi İttifaqiyyə qəzetində nəşr olunmuş Aslan və günəş simvolu 
  
  ##### 12. Qacar Xanlıqları 
  <img src='img/w_30.jpg'/>
        
  Abbas Mirzə Qacarın Naxçıvan xanlığına (1747-1828) bağışladığı bayraqlardakı Aslan və günəş simvolu

  <img src='img/w_31.jpg'/>
        
  Abbas Mirzə Qacarın Naxçıvan xanlığına (1747-1828) bağışladığı bayraqlardakı Aslan və günəş simvolu

  <img src='img/w_32.jpg'/>
        
  Rəvan Xanlığının (1747-1828) bayrağındakı Aslan və günəş simvolu
  
  ##### 13. İran İttihad Hakimiyyəti Dövrü (2 avqust 1918-5 dekabr 1918)
  <img src='img/w_33.jpg'/>
        
  İran İttihad Hakimiyyətinin (2 avqust 1918-5 dekabr 1918) bayrağıındakı Aslan və günəş simvolu
  
  ##### 14. Türk Xalçaları
  <img src='img/w_34.jpg'/>
        
  Türkmən xalçasında Aslan və günəş simvolu 
  
  ##### 15. Əsginazlar
  <img src='img/w_35.jpg'/>
        
  Türk dövlətlərindən biri olan Özbəkistan Cumhuriyyətinin əsginazlarında yer alan Aslan və günəş simvolu
    
`,

  "akkordeon.images.headline": "şəkillər"
}