import Vue from "vue";
import marked from "marked";

const LocaleSettings = Vue.observable({
  locale: "eng",
  allowedLocale: [
    'eng',
    'ger',
    'fra',
    'aze',
    'rus',
    'tur',
    'far'
  ],
  rtl: [
    'far'
  ],
  translations: {
    'eng': () => require("./../assets/i18n/eng.translation").default,
    'ger': () => require("./../assets/i18n/ger.translation").default,
    'fra': () => require("./../assets/i18n/fra.translation").default,
    'aze': () => require("./../assets/i18n/aze.translation").default,
    'rus': () => require("./../assets/i18n/rus.translation").default,
    'tur': () => require("./../assets/i18n/tur.translation").default,
    'far': () => require("./../assets/i18n/far.translation").default
  }
});


export const localGetter = {
  currentLocale() {
    return LocaleSettings.locale;
  },
  isRTL() {
    return LocaleSettings.rtl.indexOf(LocaleSettings.locale) > -1
  },
  t(id) {
    if (!LocaleSettings.translations[localGetter.currentLocale()]()[id]) {
      return `ID '${id}' missing`
    } else {
      return marked(
        LocaleSettings.translations[localGetter.currentLocale()]()[id]
      );
    }
  }
}

export const localSetter = {
  setLocale(locale) {
    if (LocaleSettings.translations[locale]) {
      LocaleSettings.locale = locale;
    }
  }
}