import VueRouter from "vue-router";

const routes = [
  /*{
      path: '/',
      component: () => import ("@/pages/DashboardPage")
  },*/
  {
    path: '/',
    component: () => import("@/pages/IndexPage"),
  } 
];

const router = new VueRouter({
  routes,
  linkActiveClass: 'nav-item active',
  scrollBehavior: (to) => {
    if (to.hash) {
      return {selector: to.hash};
    } else {
      return {
        x: 0,
        y: 0
      };
    }
  }
});

export default router;