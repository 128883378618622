export default {
  "intro.headline": `به سایت شخصی بابک پیتر پادار(بابک قاجار) خوش آمدید!

بابک قاجار یک سرمایه گذار با تابعیت آلمانی و از نوادگان اعلیحضرت شاهنشاه خاقان فتحعلی شاه قاجار امپراطور ایران است. 
`,
 "intro.text": `
  `,
      
         "intro.picture1": `سند خروج از تابعیت ایرانی

   `, 
    "intro.picture2": `1989 خبر چاپ شده در روزنامه از سرقت روز 13 نوامبر

   `,       
  "about.headline": `دربارۀ بابک پیتر پادار(بابک قاجار)`,
  "about.text": `
بابک قاجار در 11 ژوئن 1970 در برلین (غربی) به دنیا آمد. پدرش رمضان پادار ( تولد : اول ماه مه 1922، باکو، جمهوری آذربایجان شوروی ، وفات: 16 ژانویه 2007، برلین، آلمان) و مادرش مهیندخت پادار (تولد: 23سپتامبر 1947، اردبیل، ایران) است. 
        
به دلیل درگیری های سیاسی، در سال 1956 اموال پدرش به طور کامل مصادره و وی مجبور شد ایران را به مقصد آلمان ترک کند. 
        
او  تنها مجاز بود فقظ 100 دلار آمریکا به همراه داشته باشد . در نهایت هم در تاریخ 9 سپتامبر 1980 (۱۸ شهریور ۱۳۵۹) ایران از او و خانواده اش سلب تابعیت کرد . بدین ترتیب، خانوادۀ پادار جزو معدود خانواده هایی هستند که تاکنون تابعیت ایرانی آن ها سلب شده است. 

در طول جنگ سرد، بابک به عنوان یک شهروند ایرانی در برلین (غربی) بزرگ شد. وی در 17 نوامبر 1980 تابعیت آلمان را دریافت کرد و تابعیت دیگری ندارد. او مسلمان است و به زبان های آلمانی، انگلیسی، ترکی و فارسی تسلط دارد. 
  
`,

  "selfmade.headline": `انسانی خودساخته با وجود خانوادۀ پرجمعیت`,
  "selfmade.text": `
پدرش پس از ورود به برلین (غربی) به تجارت پرداخت و بعدها در املاک و مستغلات سرمایه گذاری کرد. سرانجام ، رمضان پادار به یک تاجر موفق در این حوزه تبدیل شد. وی در عین حال، پسر جوان خود را برای داشتن شایستگی های لازم آماده کرد و به او آموخت که برای استفاده از فرصت ها چگونه باید با جاه طلبی و سخت کوشی کار کند. 

در سنین پایین، بابک علاقه و اشتیاق زیادی درزمینه تجارت پیدا کرد. وی در سن 12 سالگی، بنا به درخواست خودش، اولین قرارداد اجاره را برای پدرش تنظیم و به این وسیله فعالیت حرفه ای خود را آغاز کرد. `,

  "juwelier.headline": `جوانترین جواهرساز برلین`,
  "juwelier.text": `
  در سال 1988، بابک در سن 18 سالگی، جوانترین جواهرساز برلین شد. وی در حالی که هنوز در مدرسه تحصیل می کرد، یک مغازه جواهرفروشی افتتاح نمود. یک سال قبل از آن، وی با شراکت یک جواهرفروش دیگر در برلین کار جواهرفروشی را آغاز کرده بود اما به زودی شراکت را منحل کرد و از 1989 تا 1991 به عنوان تنها مالک، تجارت را اداره نمود.
  `,

  "wurzeln.headline": `بازگشت به ریشه ها`,
  "wurzeln.text": `
بین سال های 1994 تا 2004 بابک مدیریت ثروت پدرش را بر عهده گرفت. در سال 2004 نیز او شروع به سرمایه گذاری در املاک و مستغلات و شرکت ها کرد و از آن زمان تا به امروز سرمایه گذاری موفق در سطح بین المللی است. 
`,

  // Akkordeon - Familie und Co
  "akkordeon.familie.headline": `خانواده و شجره نامۀ خانوادگی`,
  "akkordeon.familie.text": `
بابک  در مارس 1989 در برلین (غربی) با همسرش لیلا پادار (قبل از ازدواج تونچ)  آشنا شد. دوشیزه جوان، فرزند سوم پدرش «ولی تونچ» ( تولد: 10 ژوئن 1941وارنا، بلغارستان؛ وفات:1 سپتامبر 2004، چورلو، ترکیه) و مادرش «عایشه تونچ» (قبل از ازدواج آق¬دوغان) (تولد : 23  مارس 1944، میسینلی، ترکیه)، در 6 اکتبر 1972، به عنوان یک شهروند ترکیه در برلین (غربی) متولد شد. اولین دیدار آن ها در مغازۀ جواهرفروشی بابک جوان بود. 
  
  لیلا تونچ به عنوان مشتری وارد فروشگاه شد. همان طور که پشت ویترین ها ایستاده بود، بابک تصمیم گرفت این دوشیزه جوان را به عنوان همسر خود انتخاب کند. پس از مدت کوتاهی آن ها در 8 دسامبر سال 1989 رسماً ازدواج کردند. 
        
        جشن ازدواج آن ها 12 ژانویه سال 1990 در برلین (غربی)، سالن مجالس «Schloss-Saal» هتل بریستول کمپینسکی (Kurfürstendamm / Fasanenstrasse) برگزار شد. از9 ژوئن سال 1994، لیلا پادار نیز شهروند آلمان است. 
        
        لیلا و بابک صاحب دو فرزند هستند که هر دو در برلین متولد شده اند: «سردار عباس کوین» متولد 18 اکتبر 1991 و «ایزابل فاطمه ثریا» متولد 24 نوامبر سال 1998. هر دو فرزند این دو خانواده در پایتخت آلمان و به ترتیب در رشته های حقوق و مدیریت بازرگانی در حال تحصیل هستند. 
  
  `,

  // Akkordeon - Geschichte
  "akkordeon.geschichte.headline": `قاجارها و اهمیت تاریخی آن ها`,
  "akkordeon.geschichte.text": `
برای یک دورۀ 131 ساله، حاکمان فلات ایران از خاندان قاجار بودند. قاجارها از قبیلۀ بایات و شاخۀ بوز- اوق ترک های اوغوز و همچنین از زیرشاخه قبیله جلایر هستند. سلجوقیان نیز از قبیلۀ قینیق شاخۀ اوچ¬اوق، نادرشاه از قبیلۀ افشار شاخۀ بوز-اوق و عثمانی ها از قبیلۀ قایی شاخۀ بوز-اوق ترک های اوغوز بودند. در نتیجه، قاجارها و عثمانی ها خاندان های خویشاوند هستند. 
  هفت خاقان خاندان قاجار بین سال های 1794 تا 1925 بر فلات ایران سلطنت کردند.
  
  ####  خاقان های خاندان قاجار
  
  1. آقامحمد شاه قاجار (سلطنت: 1797-1794)
  2. فتحعلی شاه قاجار (سلطنت: 1834-1797)
  3. محمد شاه قاجار (سلطنت: 1848-1834)
  4. ناصرالدین شاه قاجار (سلطنت: 1896-1848)
  5. مظفرالدین شاه قاجار (سلطنت: 1907-1896)
  6. محمدعلی شاه قاجار (سلطنت: 1909-1907)
  7. احمد شاه قاجار (سلطنت: 1925-1909)
  
  بنیانگذار سلسله آقا محمدخان قاجار (خاقان آقا محمد شاه قاجار) بود که سالها جنگید و مرزهای کنونی کشور ایران میراث اوست. در سال 1796، آقا محمد خان قاجار خاقان ایران شد و متعاقباً خود را آقا محمد شاه قاجار خواند. پایتخت وی تهران بود که سابقا یکی از روستاهای شهر ری بود.
  
  در زمان او و جانشینش خاقان فتحعلی شاه قاجار (برادرزادۀ آقامحمد شاه)، فلات ایران را که تحت حکومت خاندان زند فرو پاشیده و تجزیه شده بود، مجدداً متحد و تثبیت گردید. اگر زحمات و فداکاری های خاندان قاجار نبود ایران به معنای امروزی وجود نمی داشت. 
  
   
  #### سلطنت مشروطه در ایران
  
 در زمان خاقان مظفرالدین شاه قاجار، ممالک محروسه ایران یکی از اولین کشورهای اسلامی بود که نظام سیاسی آن از سلطنت مطلقه به سلطنت مشروطه تغییر یافت. خاقان که اصلاحات بیشتری نسبت به اسلاف خود انجام داد، قانون اساسی را امضا کرد و این قانون از 30 دسامبر سال 1906 اجرایی گردید.
 
خاقان وقت، مظفرالدین شاه قاجار، در سال 1906 با انتخابات پارلمان (مجلس) موافقت کرد و بعد قانون اساسی حاوی حقوق مدنی مورد تصویب مجلس را تایید کرد. در نتیجه ایران، به عنوان یکی از اولین دولت های اسلامی با پارلمان منتخب و قانون اساسی به سلطنت مشروطه تبدیل شد. 
        
  پس از این تغییر سیاسی، نفوذ قدرتهای استعماری قدرتمند مانند انگلیس و روسیه در کشور افزایش یافت.
        
  در سال 1908 اولین ذخایر نفتی در ایران کشف شد. این کشف بزرگ با وجود فرصت های بالقوه بسیاری که ایجاد کرد، به دلیل ورود جدیتر دول استعماری به مسئله ایران به نتایج زیانباری ختم شد که مانع رشد دموکراسی در کشور گردید. از آنجا که دموکراسی جوان مانعی برای قدرتهای استعمارگر در راه استثمار نفت ایران بود، انگلیس با تب و تاب برنامه ای برای مجبور کردن ایران به تسلیم تدوین کرد. 
به منظور مقاومت بهتر در برابر قدرتهای استعماری، تورکها و دیگر مردم ایران اتحاد و همکاری قوی تری با تورکان عثمانی برقرار کردند. اتحاد دول منطقه باعث حراس استعمار پیر شد. 
  
  از قدرت گیری کشورهای اسلامی به هر طریق ممکن ممانعت می شد، زیرا استثمارگران کشورهای کوچک و ضعیف را به راحتی کنترل می کردند. آنها این کار را با کاشت بذر اختلاف بین مردم محلی انجام می دادند. در حکومت قاجار، ایران دارای جامعه ای چند فرهنگی و چند قومیِ بدون تبعیض بود. 
نظریه موهوم آریایی در قرن 19 در اروپا شناخته شده بود. با گسترش کینه و نفرت و اهانت فرصت مساعدی برای جداسازی میان مردم فراهم آمد. بریتانیا بخش هایی از اساطیر باستانی منطقه را در بنیان نهادن تفکر پان آریانیستی مورد استفاده قرار داد. در واقع، هیچ شباهت و ارتباطی بین پان آریانیسم و تنوع فرهنگی و قومی ساکنان فلات ایران وجود نداشت. نظریه های مشابه مانند پان عربیسم قبلاً با موفقیت در تخریب امپراطوری عثمانی مورد استفاده قرار گرفته بود. 
به همین دلایل، بریتانیا فهمید که فقط اشخاص و جریان هایی که تمایلات ضد تورکی دارند قابل اعتماد هستند.
سرویس مخفی انگلیس، شخصی بی سواد و خشن که سابقا یک اصطبل دار بوده و به مقام فرماندهی قزاق ها صعود کرده بود را به عنوان جانشین قاجارها انتخاب کرد. نام این شخص رضا پالانی بود. با استفاده از رضا، بریتانیا امیدوار بود بتواند یک دولت مطیع را در ایران به قدرت برساند.
رضا، طی کودتایی نظامی که توسط انگلیس سازماندهی شده بود، در سال 1925 به دموکراسی جوان ایران پایان داد.او با تهدید، ارعاب و خشونت، مجلس را مجبور کرد تا وی را به عنوان حاکم مطلق منصوب کند. لقب پهلوی توسط جاسوس بریتانیا سر اردشیر جی ریپورتر به وی داده شد. و از آن پس او رضا پهلوی خوانده شد.  در این دوران آزار و اذیت های سازمان یافته علیه قاجارها و متحدان آنها آغاز شد. 
بسیاری از اعضای عالی رتبۀ قاجار بلافاصله از کشور خارج شدند برخی به انزوا کشیده شده و برخی دیگر دستگیر شدند. از طرف دیگر، برخی منسوبین سطح پایین قاجار به امید برخورداری از مزایای مالی و اجتماعی که به دلیل درجۀ پایین خود ـ در زمان خاقان احمد شاه قاجار ـ از آنها بی بهره بودند با رضا پهلوی همکاری کردند. احمد شاه قاجار، آخرین شاه قانونی ایران، که در دوران کودتا برای معالجۀ پزشکی در فرانسه بود، هرگز برنگشت. 
حاکمیت رضا پالانی هرگز توسط سلطان احمد شاه قاجار به رسمیت شناخته نشد. پس از تسلط هیتلر، رضا، که یک فاشیست و ضد دموکرات قسم خورده بود، تغییر موضع داد و با رژیم نازی علیه بریتانیا همکاری کرد. در طول جنگ جهانی دوم رضا سعی کرد نفت ماشین آلات جنگی آلمان را تأمین کند. 
رضا پهلوی به دلیل همکاری با هیتلر توسط بریتانیا سرنگون و به آفریقای جنوبی تبعید شد و سه سال بعد در آنجا درگذشت. پسرش محمدرضا پهلوی، که خود را «آریامهر» می نامید، دیکتاتوری بی رحم بود که مانند پدرش، در سال 1979 توسط مردم سرنگون شد و به مصر گریخت. وی مانند پدرش در آفریقا درگذشت و در آنجا به خاک سپرده شد. 

      
  #### از تهران به برلین ـ تبعید پدر
        
 به دلیل نزدیکی رضا پهلوی به هیتلر، ایران توسط اتحاد جماهیر شوروی و بریتانیا اشغال گردید و با موجی از منازعات داخلی که نتیجۀ دیکتاتوری فاشیست او بود درگیر شد. 
      
  رمضان، پدر بابک شهروند ایران بود در اول ماه مه سال 1922 در باکو، جمهوری آذربایجان شوروی متولد شد. پیش از آن، والدین رمضان برای کمک به ساختن صنعت نفت از ایران به باکو نقل مکان کرده بودند. 
  
پس از تجزیه امپراطوری روسیه در سال 1918 جمهوری دموکراتیک آذربایجان تاسیس شده و طبق قوانین بین المللی به عنوان یک کشور مستقل با پایتختی باکو به رسمیت شناخته شد.این کشور نوپا در سال 1920، در اقدامی متضاد با قوانین بین المللی توسط ارتش سرخ اشغال و ضمیمه اتحاد جماهیر شوروی گردید. 
  
از آنجا که اعضای خانواده شهروندان ایران و همچنین از اشراف بودند، طی عملیات «خرقه و خنجر» در سال 1938 توسط سرویس مخفی شوروی NKVD دستگیر و به ایران تبعید شدند. همچنین آنها از تمام متعلقات و دارایی های خود نیز محروم شدند. در ایران به اجبار نام خانوادگی پادار به آنها داده شد. پادار منطقه ای در آذربایجانِ ایران است. وقتی خانواده از گرفتن این نام امتناع ورزیدند، تهدید به دستگیری شدند. 
  
 علاوه بر این، رمضان پادار، در ایران علیه دیکتاتوری پهلوی نیز مبارزه نمود. در دهۀ 1950، محمد مصدق که از نوادگان قاجار و از بنیانگذاران جبهه ملی بود توسط مردم به عنوان نخست وزیر ایران انتخاب شد. درگیری بر سر تاراج نفت ایران توسط بریتانیا به یک بحران بین المللی تبدیل شد و در ملی شدن نفت ایران توسط نخست وزیر محمد مصدق به اوج خود رسید. 
  
 با عملیات آژاکس در سال 1953، سرانجام CIA دولت منتخب و مردمی نخست وزیر محمد مصدق را سرنگون کرد. در نتیجه، رژیم دیکتاتوری پهلوی مثل همیشه توسط سرویس های مخفی غربی حمایت شد. 
  
  به دلیل آزار و شکنجۀ مداوم و همچنین دومین مصادرۀ دارایی های خانواده ـ این بار توسط دیکتاتوری پهلوی ـ رمضان پادار در سال 1956با تنها 100 دلار راهی برلین شد، جایی که برادر بزرگتر وی در آنجا زندگی می کرد.
  `,

  "akkordeon.residenzen.headline": `اقامتگاه ها، دارایی های هنری و خانوادگی `,
  "akkordeon.residenzen.text": `
  خانوادۀ امپراطوری، هنردوستان و هنرمندان زیادی را با خود همراه کرده بودند. بیش از 60 کاخ و بیشمار سنگ گرانبها و زینتی تاج و جواهرات دیگر از خود به جا گذاشتند. در اینجا یک لیست جزئی از آنها ارائه شده است. 

  #### نشان های خانوادگی قاجار
  
  نشان شیر و خورشید یکی از نشان های خانوادگی قاجار است. 
  
  
  #### کاخ های شاهنشاهی در ایران: میراث فرهنگی
    
  ##### کاخ گلستان
  
  کاخ دولتی قاجارها که کار ساخت آن در اوایل قرن نوزدهم در تهران به پایان رسید.این کاخ از سال 2013 بخشی از میراث جهانی یونسکو است.
  
 کار ساخت کاخ گلستان ابتدا در زمان طهماسب اول، خاقان سلسله صفوی، به عنوان قلعۀ نظامی شروع گردید اما در زمان آقا محمد خان که به عنوان اقامتگاه رسمی قاجارها استفاده شد به اوج اهمیت خود رسید. معماری و طرح کاخ همچنان توسط اعضای بعدی سلسله توسعه یافت. امروز کاخ هنوز هم 4.5 هکتار وسعت دارد و بازدید از آن برای عموم آزاد است.
    
  ##### مجموعۀ کاخ سعدآباد
  
  مجموعه کاخ سعدآباد که شامل 18 کاخ و تقریباً 110 هکتار زمین می باشد، ییلاق و اقامتگاه تابستانی خاقانان قاجار بود. بزرگترین کاخ، کاخ سفید و قدیمی ترین آنها، کاخ سبز است. بعلاوه، موزه هنرهای زیبا نیز در این مجموعه قرار دارد. 
  `,

     // Akkordeon - Wappen
  "akkordeon.wappen.headline": `نقش «شیر و خورشید»
  
   یا نماد فرهنگ ترک 
`, 
        
   "akkordeon.wappen.text":`
  بر اساس یک باورغلط  اما رایج، گفته می شود هیچ ارتباطی میان نماد «شیر و خورشید» نقش بسته  بر پرچم ایران در دوره پهلوی با مردم ترک وجود نداشته است. اما بررسی  داده های تاریخی  به وضوح نشان می دهد  این نماد  به  فرهنگ و تمدن ترک تعلق دارد.

  در بررسی سکه و اسکناس ها، سنگ قبرها، آثار معماری، جام ها، مصنوعات شیشه ای، آجرها، پرچم ها و دیگر اشیا و مصنوعات دوره های مختلف  تاریخ ترک و به ویژه در اساطیر ترک ، بارها و بارها نماد «شیر و خورشید» خودنمایی می کند.
        
  از سوی دیگر نقش «شیر و خورشید» را هرگز نمی توان به فرهنگ پارسی  یا دین زرتشتی نسبت داد. در تخت جمشید، شهر یزد و دیگر مراکز تاریخی فرهنگ پارس در هیچ زمانی  اثری از نماد «شیر و خورشید»  دیده نشده است .
   
  واقعیت این است که «مجتبی مینوی» عوام فریب پیرو ناسیونالیسم پارسی که از ریشه ترکی نقش «شیر و خورشید» آگاهی داشت بر این  باور بود  که این نقش باید از فرهنگ ایرانی حذف شود. 

  * منبع : مجتبی مینوی ، تاریخ و فرهنگ جلد سوم ، شرکت سهامی انتشارات خوارزمی . تهران 1369 صفحات 352 ، 359 ، 374 و 378
        
  از میان دولت های ترک که درآن ها از نقش «شیر و خورشید » استفاده شده است نیز می توان به نام های زیر اشاره کرد.
کوشانی ها (330 تا 375)، شاهان ترک (665 تا 850)، غزنویان (963 تا 1186)، سلجوقیان بزرگ (1037 تا 1194)، سلجوقیان آناتولی (1077تا 1307)، خوارزمشاهیان (1077تا 1231)، مملوکیان مصری (1250 تا 1517)، ایلخانیان (1256تا 1336)، عثمانی ها (1299-1922)، جلایریان  (1336تا 1432)، تیموریان (1370تا 1507)، آق قویونلوها (1378تا 1508)، قارا قویونلوها (1380تا 1469) ) ، صفویان (1501تا 1736)، مغول ها (1526تا 1858)، قطب شاهی ها (1512تا1687)، افشاریان (1736تا 1796 )، سلسله قاجار (1779تا 1925)، خان نشین نخجوان (1747 تا 1828)، خان نشین ایروان (1747 تا 1828)، حکومت اتحاد ایران (2 اگوست 1918 تا 5 دسامبر 1918 )  .
در ادامه و با توجه به گاهشمار تاریخی ، به  نمونه هایی از اشیاء با نقش یا نماد «شیر و خورشید»  اشاره می شود.
      
  ##### 1.	دوران ترک شاهی ها (665 تا 850)
  <img src='img/w_01.jpg'/>

  نماد شیر و خورشید در سکه های دوران ترک شاهی ها 
        
  ##### 2.دوران سلجوقیان (قرون 11 تا 14)
  <img src='img/w_02.jpg'/>
        
  نماد "شیر و خورشید" در سکه های دوران سلجوقیان
        
  <img src='img/w_03.jpg'/>
        
  نماد "شیر و خورشید" در ظروف از دوران سلجوقیان
        
  <img src='img/w_04.jpg'/>
        
  نماد "شیر و خورشید" بر روی بناهای دوران سلجوقیان آناتولی
        
  <img src='img/w_05.jpg'/>
        
  نماد "شیر و خورشید" در سکه های دروان غیاث الدین کیکاووس سلجوقی (1240)

  ##### 3. دوران خوارزمشاهیان (1077 تا 1231)
  <img src='img/w_06.jpg'/>
        
  نماد "شیر و خورشید" در سکه های دوران جلال الدین محمد خوارزمشاه
  
  ##### 4.دوران ایلخانی (1256 تا 1336)
  <img src='img/w_07.jpg'/>
        
  نماد "شیر و خورشید" در سکه های در دوران ایلخانی
  
  <img src='img/w_08.jpg'/>
        
  نماد "شیر و خورشید" در نقش نگاره های دوران ایلخانی
  
  ##### 5. دوران تیموری (1370 تا 1507)
  <img src='img/w_09.jpg'/>
        
   نماد "شیر و خورشید" در پرچم دوران تیموری "1423"
  
  <img src='img/w_10.jpg'/>
        
  نماد "شیر و خورشید" در بنای مدرسه الغ بیگ

  ##### 6. دوران عثمانی (1299 تا 1922)
  <img src='img/w_11.jpg'/>
        
  نماد "شیر و خورشید" در پلاک های مربوط به پیروزی نبرد چاناک قلعه
  
  ##### 7. دوران صفوی
  <img src='img/w_12.jpg'/>
        
  نماد "شیر و خورشید" در پرچم دوران صفوی
  
  <img src='img/w_13.jpg'/>
        
  نماد "شیر و خورشید" در سفر محمدرضا بیگ سفیر صفویان به فرانسه
  
  ##### 8.دوران بابوریان(گورکانیان هند)
  <img src='img/w_14.jpg' />
        
  نماد "شیر و خورشید" در پرچم دوران بابوریان
  
  <img src='img/w_15.jpg' />
        
  پرچم دیگری منقش به نماد "شیر و خورشید" مربوط به دوران بابوریان
  
  <img src='img/w_16.jpg' />
        
  نماد "شیر و خورشید" در سکه های دوران جهانگیر شاه بابوری

  <img src='img/w_17.jpg' />
        
  نماد "شیر و خورشید" در  سکه دیگری از دوران جهانگیرشاه بابوری

  ##### 9.دوران قطب شاهی ها(1518  تا 1687)
  <img src='img/w_18.jpg'/>
        
  نماد "شیر و خورشید" در دوران قطب شاهی ها
  
  ##### 10 . دوران افشاریان (1736 تا 1796)
  <img src='img/w_19.jpg'/>
        
  نماد "شیر و خورشید" در پرچم دوران افشاریان 
  
  <img src='img/w_20.jpg'/>
        
  نماد "شیر و خورشید" در نشان خاقانی نادر شاه افشار
  
  ##### 11. دوران قاجار (1779 تا 1925)
  <img src='img/w_21.jpg'/>
        
  نماد "شیر و خورشید" در پرچم دوران آقا محمدخان قاجار
  
  <img src='img/w_22.jpg'/>
        
  نماد "شیر و خورشید" در پرچم های دوران فتحعلی شاه قاجار

  <img src='img/w_23.jpg'/>
        
  نماد "شیر و خورشید" در سکه های دوران فتحعلی شاه قاجار (1830)
  
  <img src='img/w_24.jpg'/>
        
  نماد شیر و خورشید در پرچم دوران محمد شاه قاجار

  <img src='img/w_25.jpg'/>
        
  نماد "شیر و خورشید" در پرچم دوران ناصرالدین شاه قاجار

  <img src='img/w_26.jpg'/>
        
  نماد "شیر و خورشید" در تمبرهای دوران محمدعلی شاه قاجار (1906)

  <img src='img/w_27.jpg'/>
        
   نماد "شیر و خورشید" در سکه های دوران احمد شاه قاجار

  <img src='img/w_28.jpg'/>
        
  نماد "شیر و خوردشید" پرچم های نظامی دوران قاجار
  
  <img src='img/w_29.jpg'/>
        
  نماد "شیر و خورشید" در روزنامه "وقایع اتفاقیه" دوران قاجار
  
  ##### 12. دوران خانات (قرون 18 و 19 میلادی)
  <img src='img/w_30.jpg'/>
        
  

  <img src='img/w_31.jpg'/>
        
  نماد "شیر و خورشید" در پرچم هایی که عباس میرزا به خان نشین نخجوان هدیه کرد (1747-1828)

  <img src='img/w_32.jpg'/>
        
  نماد "شیر و خورشید" در پرچم خان نشین ایروان (1747 تا 1828)
  
  ##### 13. دوران حکومت اتحاد ایران (2 آگوست 1918 تا 5 دسامبر 1918)
  <img src='img/w_33.jpg'/>
        
   نماد "شیر و خورشید" در پرچم حکومت اتحاد ایران (2 آگوست 1918 تا 5 دسامبر 1918)
  
  ##### 14. در فرش ها و قالیچه های ترکی
  <img src='img/w_34.jpg'/>
        
  نماد "شیر و خورشید" در فرش های ترکمن
  
  ##### 15. پول رایج جمهوری ازبکستان
  <img src='img/w_35.jpg'/>
        
  نماد "شیر و خورشید" در پول رایج جمهوری ازبکسستان
    
`,

  "akkordeon.images.headline": "تصاوير"
}