export default {
  "intro.headline": `Babek Peter Padar (Babek Kacar)’ın
  
  Kişisel Sitesine Hoşgeldiniz!`,
 "intro.text": `
  `,
        
         "intro.picture1": `İran Vatandışlığından Çıkış Belgesi

   `, 
    "intro.picture2": `13 Kasım 1989 soygunu sonrası gazete haberi

   `,       
        
  "about.headline": `Babek Peter Padar (Babek Kacar) Hakkında`,
  "about.text": `
  Almanya uyruklu bir yatırımcı olan Babek Kacar, İran İmparatoru Feht-ali Şah Kacar’ın soyundan gelmektedir. 

  Babası Ramazan Padar (1 Mayıs 1922, Bakü, Azerbaycan Sovyet Cumhuriyeti - 16 Ocak 2007, Berlin, Almanya), annesi ise Mahindokht Padar (23 Eylül 1947, Erdebil, İran) olan Babek Kacar 11 Haziran 1970 yılında Batı Berlin’de dünyaya gözlerini açtı.

  Siyasi anlaşmazlıklardan kaynaklanarak 1956 yılında babasının, tüm mülklerinin istimlak edilmesinin ardından, zorunlu olarak İran’ı terk edip Almanya’ya gitmesi istenmiştir. Giderken yanında sadece 100 Amerikan Dolar’ı götürmesine müsaade edilmiştir. İran hükumeti, onu ve ailesini 9 Eylül 1980’de vatandaşlıktan çıkarmıştır. Böylece Padar ailesi İran hükumeti tarafından vatandaşlıktan çıkartılan az sayıdaki aileler arasında yer almışlardır.

  Soğuk Savaş esnasında Babek Kacar, Batı Berlin’de İran vatandaşı olarak yaşamını sürdürmüştür. 1980 yılının 17 Kasım tarihinde Almanya vatandaşlığını kabul etmiştir. Başka bir ülke vatandaşlığı ise bulunmamaktadır. Almanca, İngilizce, Türkçe ve Farsça’yı bilen Babek Kacar İslam dini inancına sahiptir.

    
  `,

  "selfmade.headline": `Geniş Aileye Rağmen Kendini Geliştirmeyi Başaran Adam `,
  "selfmade.text": `
  Batı Berlin’e vardıktan sonra babası işadamı olarak çalışmaya başlamış ve sonrasında gayrimenkul yatırımları yapmaya yönelmiştir. Sonucunda ise Ramazan Padar gayrimenkul alanında başarılı bir işadamı olmayı başarmıştır. Aynı zamanda genç oğlunu da gereken tüm meziyetlere sahip olabilmesi için hazırlamış ve ona tüm fırsatları değerlendirebilmesi için çok çalışması gerektiğini aşılamıştır.

  Erken yaşlarda Babek bu alana olan ilgisiyle hızlı bir başlangıç yapmayı başarmıştır. Daha 12 yaşındayken babası için kendi isteğiyle ilk kira sözleşmesini yapmayı başarmış, bu vesileyle de kariyerine adım atmıştır.
`,

  "juwelier.headline": `Berlin’in En Genç Kuyumcusu`,
  "juwelier.text": `
  1989 senesinde, daha 18 yaşındayken Babek, Berlin’in en genç kuyumcusu olmayı başarmıştır. Henüz okul hayatına devam ederken bir kuyumcu dükkanı açmış, 1989-1991 yılları aralığında işi tek başına yönetmiştir. Kendi işinin sahibi olmadan bir sene önce Berlin’de bulunan başka bir kuyumcu ile ortaklık kurmuş ve kısa sürenin ardından ortaklığı feshetmiştir. 
  `,

  "wurzeln.headline": `Köklerine Dönüş`,
  "wurzeln.text": `
  1994 ve 2004 yılları arasında Babek babasının mal varlığı yönetimini üstlenmiştir. 2004 yılında gayrimenkul ve holdinglere yatırım yapmaya başlamış ve o zamandan beri uluslararası alanda başarılı bir yatırımcı olarak çalışmalarını sürdürmektedir. 
  `,

  // Akkordeon - Familie und Co
  "akkordeon.familie.headline": `Aile ve Aile Ağacı `,
  "akkordeon.familie.text": `
  Babek, Leyla Padar (evlenmeden önceki soyadı Tunç) ile evlidir. Eşiyle Mart 1989’da Batı Berlin’de tanışmıştır. Babası Veli Tunç ve  (10 Haziran 1941, Varna, Bulgaristan-1 Eylül 2004, Çorlu, Türkiye) annesi Ayşe Tunç’un (evlenmeden önceki soyadı Akdoğan, 23 Mart 1944, Misinli, Türkiye) üçüncü çocukları olan Leyla Padar 6 Ekim 1972’de Batı Berlin’de Türk vatandaşı olarak dünyaya gelmiştir. Babek Kacar’la, ona ait kuyumcu dükkanında tanışmışlardır. Babek mağazaya müşteri olarak gelen Leyla Tunç’u vitrinlerin arkasında görür görmez onunla evlenmeye karar vermiştir. Kısa bir süre sonra 8 Aralık 1989’da Batı Berlin’de nikah törenleri gerçekleştirilmiştir. Düğün ise 12 Ocak 1990’da Bristol Hotel Kempinski’nin (Kurfürstendamm / Fasanenstrasse) “Schloss-Saal” Balo Salonu’nda yapılmıştır. 9 Haziran 1994’te Leyla Padar Almanya vatandaşlığını kabul etmiştir.

  Leyla ve Babek çiftinin Berlin’de dünyaya gelen iki evladı vardır: oğulları Serdar-Abbas Kevin, 18 Ekim 1991, kızları Isabelle Fatima-Soraya ise 24 Kasım 1998 doğumludur. İkisi de Almanya’nın başkentinde sırasıyla Hukuk ve İşletme bölümlerinde eğitim almaktadırlar.

  `,

  // Akkordeon - Geschichte
  "akkordeon.geschichte.headline": `Kacar Hanedanı ve Tarihteki Yeri`,
  "akkordeon.geschichte.text": `
  Kacarlar 131 yıllık bir süre boyunca İran Yaylası’na hakim olmuşlardır. Oğuz Türkleri’nin Boz-ok kolundan Bayat boyuna aynı zamanda ise Celayir boyunun alt kollarından biri olan Kacar oymağı Çengiz Han’ın (1155- 18 Ağustos, 1227) soyundandır. Kacarlar, Çengiz Han’ın torunu ve Kubilay Han’ın (23 Eylül 1215-18 Şubat 1294, Pekin) küçük kardeşi olan Hulagu Han’ın (1217-8 Şubat 1265) soyundan gelmektedirler. İlerleyen yıllarda Kubilay Han, Yuan Hanedanlığı’nı kurmuş ve Çin’e hakim olmuştur. Türk Selçuklular, Oğuz Türkleri’nin Üçok kolundan Kınık; Nadir Şah, Bozoklar’ın Afşar ve Osmanlılar ise Bozoklar’ın Kayı boyundandırlar.  Yani, kısacası Kacarlar ve Osmanlılar birbirleriyle akrabadırlar. Kacar ailesinin yedi hakanı 1794-1925 yılları arasında İran’a hükmetmiştir.
  
  #### Kacar Hanedanlığı Hakanları (İmparatorları)
  
  1. Ağa Muhammed Şah Kacar (1794-1797 yıllarında hüküm sürmüştür)
  2. Feth Ali Şah Kacar (1797-1834 yıllarında hüküm sürmüştür)
  3. Muhammed Şah Kacar (1834 -1848 yıllarında hüküm sürmüştür)
  4. Nasreddin Şah Kacar (1848-1896 yıllarında hüküm sürmüştür)
  5. Muzaffereddin Şah Kacar (1896-1907 yıllarında hüküm sürmüştür)
  6. Muhammed Ali Şah Kacar (1907-1909 yıllarında hüküm sürmüştür)
  7. Ahmed Şah Kacar (1909 -1925 yıllarında hüküm sürmüştür)
 
  Hanedanlığın kurucusu, uzun yıllar boyunca savaşan ve İran’ın bugünkü sınırlarını belirleyen Ağa Muhammed Han Kacar (daha sonra Ağa Muhammed Şah Kacar) olmuştur. 1796’da Ağa Muhammed Han Kacar, İran’ın hakanı (imparatoru) olmuş ve hemen ardından Ağa Muhammed Şah Kacar adını almıştır. İran’ın başkenti, o zamanlar henüz tarihsel değer taşımayan bir köy durumundaki Tahran olmuştur.

  Ağa Muhammed Şah Kacar’ın ve yeğeni olan halefi Hakan Feth Ali Şah Kacar\`ın yönetimi esnasında, Zend Hanedanlığı liderleri tarafından parçalanmak üzere olan İran Yaylası yeniden birleştirilmiştir. Kacar yönetimi mevcut olmasaydı yer yüzünde bugün var olan şekliyle İran olmayacaktı.

    
  #### İran’da Anayasal Meşrute Monarşisi
  
  Hakan Muzaffereddin Şah Kacar döneminde İran Korunmuş Memleketleri (İran Memalik-i Mahrusesi), ilk İslam devletlerinden biri olarak mutlak monarşiden anayasal monarşiye geçiş yapmıştır. 

  Seleflerine göre daha reformcu olan hakan, 30 Aralık 1906’da yürürlüğe giren anayasayı imzalamıştır.

  O dönemin hakanı Muzaffereddin Şah Kacar, 1906’da bir parlamento (Macles) seçilmesini kabul etmiştir, parlamento temel sivil hakları içeren bir anayasayı (Kânûn-i Esâsî) onamıştır. Sonuçta ilk İslam devletlerinden biri olan İran, seçilmiş bir parlamentosu ve anayasası ile meşrute (anayasal) monarşine geçiş yapmıştır. 

  Söz konusu siyasi değişimin ardından, Büyük Britanya ve Rusya gibi sömürge güçler ülkede nüfuz ve etkinliklerini daha da artırmışlardır. 

  1908’de İran’da ilk petrol yatakları keşfedilmiştir. Bu büyük keşif ülkenin gelişmesi için yeni fırsatlar doğurmasına rağmen sömürücü güçlerin ülkeye olan müdahelelerinin daha da ciddi bir biçimde artmasına neden olmuştur. Bunun sonucunda ise ülkede görülmeye başlayan  genç demokrasinin gelişimi adeta engellenmiştir.        

  Söz konusu genç demokrasi, sömürge güçlerin ülke petrolünü ele geçirmesini tehlikeye düşürmekteydi. Bu nedenle de İngiltere alelacele haerekete geçerek ülkeyi teslim olmaya zorlamak için bir plan geliştirmeye yönelmiştir.

  İran’da yaşayan Türkler ve ilgili olan diğer kavimler, sömürge güçlerine birlikte direnebilmek için Türkiye ile daha sağlam bir işbirliği kurmuşlardır. Sömürmek istedikleri bölge halkının güçlenen hakimiyeti Britanya’yı çok korkutmuş ve böylece İslamî ülkelrin güçlenmesi değişik yöntemlerle engellenmiştir. Sömürgeci güçler daha kolay kontrol edebilecekleri küçük ve zayıf ülkeleri yeğlemişlerdir. Yerel halk arasında anlaşmazlık tohumları ekerek onları birkaç küçük ve zayıf ülke haline getirip sömürmek çok daha kolay olacağından bu ülkelerin bir birlik oluştırmaları mümkün olan her yolla engellenmiştir. 

  Kacar yönetimi döneminde İran, ayrımcılıktan arınmış, çok kültürlü ve değişik etniklerden oluşan bir topluma sahipti.

  Hayalî Aryan Teorisi, 20. yüzyılın başında Avrupa’da insanları nefretle, hor görerek bölme yöntemi olarak artık biliniyordu. Britanya, Pan-İranizm’i ortaya çıkarmak için bölgenin antik mitolojisinden bir kısmını araç olarak kullanmaya yönelmiştir. Pan-İranizm ile İran Yaylası’nda yaşayan insanların kültürü ve etnik dokuları arasında hiçbir benzerlik veya bağlantı bulunmamaktadır. Pan-Arabizm’e benzer teoriler Osmanlı İmparatorluğu’nun yıkılmasında başarıyla kullanılmıştır. 

  Bu nedenlerle İngiltere siyasi çıkarları için, yalnızca Türk karşıtı şahıs ve akımlara güvenmekte ve onları kullamnmayı kararlaştırmıştır.

  İngiliz gizli servisi, Kazak Birliği komutanı pozisyonuna yükselen, kaba, okuma yazma bilmeyen ve eski seyis yamağı Rıza Palani’yi halefi olarak sahneye çıkarmıştır. İngiltere, Rıza’nın aracılığı ile itaatkar bir İran devletini kurmayı planlamaktaydı. 

  İngiltere’nin düzenlediği bir askeri darbe ile Rıza, 1925’te genç demokrasiyi şiddet yoluyla yok etmiş ve baskı uygulayarak parlamentoyu kendisini mutlak yönetici olarak atamaya zorlamıştır. Pehlevi lakabı Britanya ajanı Erdeşir Reporter tarafından Rıza’ya verilmiştir. Bundan sonra, Rıza Pehlevi adını almış, ardından Kacarlara ve müttefiklerine yönelik organize zulüm başlamıştır.

  Pek çok yüksek rütbeli Kacar yabancı ülkelere kaçmış, yeraltına girmiş veya tutuklanmıştır. Öte yandan, bazı düşük rütbeli Kacarlar, İmparator Ahmed Şah Kacar’ın liderliğinde - düşük rütbeleri nedeniyle - elde edemeyecekleri mali ve sosyal avantajlar umuduyla Rıza Pehlevi ile işbirliği yapmışlardır. Darbe esnasında tıbbi tedavi için Fransa’da bulunan İran’ın son meşru imparatoru olan İmparator Ahmed Şah Kacar ise bir daha geri dönmemiştir. Rıza Palani’nin hakimiyeti hiç bir zaman Ahmed Şah Kacar tarafından tanınmamıştır. 

  Hitler’in güçlenmesiyle faşist ve anti-demokrat olan Rıza, taraf değiştirmiş ve İngiltere’ye karşı Nazi rejimi ile işbirliği yapmıştır. Rıza, II Dünya Savaşı boyunca Alman savaş makinelerine petrolünü sağlamak için çaba harcamıştır. 

  Rıza Pehlevi, Hitler’le işbirliğinden dolayı İngiltere tarafından devrilmiş ve Güney Afrika’ya sürülmüştür. Rıza üç yıl sonra orada vefat etmiştir. Kendisine “Aryanların Güneşi” anlamına gelen “Arya-mihr” lakabını takan oğlu Muhammed Rıza Pehlevi de babası gibi acımasız bir diktatör idi ve hakimiyeti 1979’da halk tarafından devrilmiş kendisi Mısır’a kaçmıştır. Babası gibi o da Afrika’da ölmüş ve orada toprağa verilmiştir.

      
  #### Tahran’dan Berlin’e - Babasının Sürgünü
        
  Rıza Pehlevi’nin Hitler’e yakınlığı nedeniyle İran, Faşist diktatörlüğün bir sonucu olarak iç çatışmalar ve ayrılıkçılıkla birlikte Sovyetler Birliği ve İngiltere tarafından işgal edilmiştir.
      
  Babek’in babası, İran vatandaşı Ramazan Padar, 1 Mayıs 1922’de Sovyet Azerbaycanı’nın Bakü kentinde doğmuştur. Daha öncesinde, Ramazan’ın ailesi, yerel petrol endüstrisinin kurulmasına yardımcı olmak üzere İran’dan Bakü’ye taşınmışlardır.
  
  Azerbaycan Demokratik Cumhuriyeti, 1918 yılında Rusya İmparatorluğu’nun dağılmasının hemen ardından kurulmuştur. Başkenti Bakü olan söz konusu cumhuriyet, uluslararası hukukta egemen bir devlet olarak tanınmış ve 1920 yılında Sovyetler Birliği tarafından uluslararası hukuka aykırı bir şekilde işgal ve ilhak edilmiştir.

  Aile üyeleri, aristokrat olmalarının yanı sıra İran vatandaşı olduklarından, tüm mal varlıklarından mahrum bırakılmış ve 1938’de Sovyet Gizli Servisi NKVD tarafından “Pelerin ve Hançer” operasyonuyla tutuklanarak İran’a sürülmüşlerdir. Orada, aileye iradeleri dışında, keyfi olarak seçilen Padar soyadı verilmiştir. Padar, İran Azerbaycanı’nda bir bölgenin adıdır. Aile bu soyadını almayı kabul etmek istemediğini beyan ettiğinde ise tutuklanmakla tehdit edilmişlerdir.

  Ayrıca Ramazan Padar, İran’da sadece ülkesinin egemenliği için değil, Pehlevi diktatörlüğüne karşı da savaşmıştır. 1950’lerde, Kacar soyundan gelen Muhammed Musaddık halk tarafından İran Başbakanı olarak seçilmiş ve Ulusal Cephe (Cebhe-yi Milli) kurucularından biri olmuştur. İngiltere’nin İran petrolünü yağmalama çabası uluslararası bir kriz haline dönüşmüş ve İran petrolünün Başbakan Muhammed Musaddık tarafından millileştirilmesiyle doruk noktasına ulaşmıştır.

  1953’teki Ajax Operasyonu ile CIA nihayet halk tarafından seçilmiş olan Başbakan Muhammed Musaddık’ın hükümetini devirmiştir. Sonuç olarak, her zaman olduğu gibi diktatör Pehlevi rejiminin hakimiyeti batılı gizli servisler tarafından temin edilmiştir.

  Devam eden zulüm ve tüm ailenin ikinci kez mülklerinden mahrum bırakılması ile birlikte - bu sefer Pehlevi diktatörlüğü tarafından - Ramazan Padar, cebinde sadece 100 Dolar ile, 1956’da ağabeyinin yaşadığı  Berlin şehrine sürgüne gönderilmiştir.

 `,

  "akkordeon.residenzen.headline": `Yerleşim, Sanat ve Aile Varlıkları `,
  "akkordeon.residenzen.text": `
  
  Hanedan ailesi birçok insanın sanatı sevmesine vesile olmayı başarmış, çok sayıda sanatçı ve sanatsevri himayesi altına almıştır. Onlardan kalan 60’tan fazla saray, taç ve diğer değerli taşlar gibi paha biçilmez ve sayısız mücevherler günümüze ulaşmıştır. Aşağıdakiler bunların sadece bir kısmını kapsamaktadır.
  
  #### Kacar Hanedanı Arması
        
  “Aslan ile güneş” arması, Kacar ailesinin armalarından biridir.      
  
  #### İran’daki Hakanlık (İmparatorluk) Sarayları: Külütürel Miras
    
  ##### Gülistan Sarayı
  
  Kacar hükümetinin Tahran’daki sarayı olan “Gülistan Sarayı”nın inşası 19. yüzyılın başlarında tamamlanmıştır. Gülistan Sarayı, 2013 yılından beri UNESCO’nun Dünya Mirası listesinde yer almaktadır.
  
  Söz konusu sarayın temelleri bir askeri kale olarak Safevi hanedanının hakanı I. Tahmasip zamanında atılmış, Ağa Muhammed Han zamanında ise Kacarlar’ın resmi ikametgahı olarak kullanılmış ve öneminin doruğuna ulaşmıştır. Sarayın mimarisi ve düzeni, sonraki hanedan üyeleri tarafından geliştirilmeye devam etmiştir. Saray günümüze kadar ihtişamını korumayı başarmıştır, 4.5 hektar büyüklüğündedir ve halka açıktır.

    
  ##### Saadabad Saray Kompleksi
  
  Saadabad Saray Kompleksi, 18 saraydan oluşmaktadır ve yaklaşık 110 hektarlık bir alanı kapsamaktadır. Kacar hakanlarının yazlık ikametgahı olarak istimal edilmiştir. Sarayların en büyük olanı Beyaz Saray ve en eski olanı Yeşil Saray’dır. Ayrıca kompleks kapsamında Güzel Sanatlar Müzesi de yer almaktadır.
  `,

 // Akkordeon - Wappen
  "akkordeon.wappen.headline": `ASLAN VE GÜNEŞ MOTİFİ
  
   YAHUT TÜRK MEDENİYETİNDEKİ İKON
`, 
        
   "akkordeon.wappen.text":`
  Pehlevi döneminde İran bayrağında yer alan "Aslan ve Güneş" motifinin, yaygın bir yanılgıya dayanılarak Türk halklarıyla ilgisi olmadığı söylenir. Ancak tarihi verileri incelendiğinde durumun böyle olmadığı ve bu motifin Türk medeniyetine ve kültürüne ait olduğu açıkça görülür. Türk mitolojisi de dahil olmak üzere Türk tarihinin birçok dönemine ait sikkeler, banknotlar, mezar taşları, mimari eserler, kadehler, cam işleri, tuğlalar, bayraklar ve diğer nesne ve eserlerde "Aslan ve Güneş" motif ve sembolüne sıklıkla rastlıyoruz.

  "Aslan ve Güneş" motifi hiçbir zaman Pers kültürüne veya Zerdüşt dinine atfedilemez, ona Persepolis, Yezd şehrinde veya Pers kültürünün diğer merkezlerinde rastlanmaz.
        
  Aslında "Aslan ve Güneş" motifinin Türk kökenli olduğunun farkında olan İranlı milliyetçi demagog Muctaba Minevi, bu motifin İran kültüründen çıkarılması gerektiği görüşündeydi.*
   
  *(Kaynak: Muctabâ Mînevî, Târîh ve Ferheng, Çâp: 3, Şirket-i Sahâmî-yi İntişârât-ı Hârezmî, Tahran, h. ş. 1369, s. 352, 359, 374, 378.)
        
  Söz konusu motifin var olduğu tarihi Türk devletlerinden şunların adlarını verebiliriz: 
        
  Kuşanlar (30-375), Türk Şahiler (665-850), Gazneliler (963-1186), Büyük Selçuklular (1037-1194), Anadolu Selçukluları (1077-1307), Harezmşahlar (1077-1231), Memlûk Devleti (1250-1517), İlhanlılar (1256-1336), Osmanlılar (1299-1922), Celâyirîler (1336-1432), Timurlular (1370-1507), Akkoyunlular (1378-1508), Karakoyunlular (1380-1469), Adilşahlar (1490-1686), Safevîler (1501-1736), Babürlüler (1526-1858), Kutbşahlar (1512-1687), Afşarlar (1736-1796), Kaçar Hanedanı (1779-1925), Nahçıvan Hanlığı (1747-1828), Revan Hanlığı (1747-1828), İran İttihat Hükumeti (2 Ağustos 1918 - 5 Aralık 1918) vb. 
        
  Aşağıda tarihsel kronolojiyi göz önünde bulundurarak "Aslan ve Güneş" motifi veya simgesinin yer aldığı objelerden örnekler vermekteyiz. 
        
  ##### 1. Türk Şahiler Dönemi (665-850)
  <img src='img/w_01.jpg'/>

  Türk Şahiler Dönemi Sikkelerinde "Aslan ve Güneş" Motifi 
        
  ##### 2. Selçuklu Dönemi (XI-XIV. yy.) 
  <img src='img/w_02.jpg'/>
        
  Selçuklu Dönemi Sikkesinde (1037-1194) "Aslan ve Güneş" Motifi 
        
  <img src='img/w_03.jpg'/>
        
  Selçuklu Dönemi Havanında "Aslan ve Güneş" Motifi   
        
  <img src='img/w_04.jpg'/>
        
  Anadolu Selçuklu Dönemi Yapıtlarında "Aslan ve Güneş" Motifi
        
  <img src='img/w_05.jpg'/>
        
  Selçuklu Gıyaseddin Keyhüsrev Dönemi Sikkesinde "Aslan ve Güneş" Motifi (1240)

  ##### 3. Harezmşahlar Dönemi (1077-1231)
  <img src='img/w_06.jpg'/>
        
  Celaleddin Harezmşah Mengüberti Dönemi Sikkesinde "Aslan ve Güneş" Motifi

  ##### 4. İlhanlı Dönemi (1256-1336)
  <img src='img/w_07.jpg'/>
        
  İlhanlı Dönemi Sikkelerinde "Aslan ve Güneş" Motifi
        
  <img src='img/w_08.jpg'/>
        
  İlhanlı Dönemi Seramiklerinde "Aslan ve Güneş" Motifi
  
  ##### 5. Timurlu Dönemi (1370-1507)
  <img src='img/w_09.jpg'/>
        
  Timurlu Dönemi Bayrağında / Tuğunda "Aslan ve Güneş" Motifi (1423)

  <img src='img/w_10.jpg'/>
        
  Uluğ Bey Medresesinde "Aslan ve Güneş" Motifi

  ##### 6. Osmanlı Dönemi (1299-1922)
  <img src='img/w_11.jpg'/>
        
  Çanakkale Zaferi ile İlgili Anı Rozetinde "Aslan ve Güneş" Motifi 
  
  ##### 7. Safevi Dönemi (1501-1736)
  <img src='img/w_12.jpg'/>
        
  Safevi Dönemi Bayrağında "Aslan ve Güneş" Motifi
  
  <img src='img/w_13.jpg'/>
        
  Safevi Elçisi Muhammed Rıza Beyin Paris Seferi Resmindeki Safevi Bayrağında / Tuğunda "Aslan ve Güneş" Motifi 
  
  ##### 8. Babürlü Dönemi (1526-1858)
  <img src='img/w_14.jpg' />
        
  Babürlü Bayrağında "Aslan ve Güneş" Motifi
  
  <img src='img/w_15.jpg' />
        
  Bir Başka Babürlü Bayrağında "Aslan ve Güneş" Motifi
  
  <img src='img/w_16.jpg' />
        
  Babürlü Cihangir Şah Dönemi Sikkesinde "Aslan ve Güneş" Motifi 

  <img src='img/w_17.jpg' />
        
  Bir Başka Babürlü Cihangir Şah Dönemi Sikkesinde "Aslan ve Güneş" Motifi

  ##### 9. Kutbşahlar Dönemi (1518-1687)
  <img src='img/w_18.jpg'/>
        
  Kutbşahlarda "Aslan ve Güneş" Motifi
  
  ##### 10. Afşar Dönemi (1736-1796)
  <img src='img/w_19.jpg'/>
        
  Afşar Dönemi Bayrağında "Aslan ve Güneş" Motifi
  
  <img src='img/w_20.jpg'/>
        
  Nadir Şah Afşar’ın Hakanlık Damgasında "Aslan ve Güneş" Motifi (1746)
  
  ##### 11. Kacar Dönemi (1779-1925)
  <img src='img/w_21.jpg'/>
        
  Ağa Muhammed Şah Kacar Dönemi Bayrağında "Aslan ve Güneş" Motifi
  
  <img src='img/w_22.jpg'/>
        
  Feth Ali Şah Kacar Dönemi Bayraklarında "Aslan ve Güneş" Motifi

  <img src='img/w_23.jpg'/>
        
  Feth Ali Şah Kacar Dönemi Sikkelerinde (1830) "Aslan ve Güneş" Motifi 
  
  <img src='img/w_24.jpg'/>
        
  Muhammed Şah Kacar Dönemi Bayrağında "Aslan ve Güneş" Motifi

 <img src='img/w_25.jpg'/>
        
  Nasreddin Şah Kacar ve Sonrasında Kacar Devleti Bayrağında "Aslan ve Güneş" Motifi

  <img src='img/w_26.jpg'/>
        
  Muhammed Ali Şah Kacar Dönemine Ait Pulda "Aslan ve Güneş" Motifi (1906)

  <img src='img/w_27.jpg'/>
        
  Ahmed Şah Kacar Dönemi Gümüş Sikkesinde "Aslan ve Güneş" Motifi

  <img src='img/w_28.jpg'/>
        
  Kacar devletin armasında "Aslan ve Güneş" Motifi
  
  <img src='img/w_29.jpg'/>
        
  Kacar Dönemi Vakayi-i İttifakiye Gazetesinde "Aslan ve Güneş" Motifi
  
  ##### 12. Kacar Hanlıkları
  <img src='img/w_30.jpg'/>
        
  Abbas Mirza Kacar’ın Nahçıvan Hanlığına (1747-1828) Armağan Ettiği Bayraklarda "Aslan ve Güneş" Motifi

  <img src='img/w_31.jpg'/>
        
  Abbas Mirza Kacar’ın Nahçıvan Hanlığına (1747-1828) Armağan Ettiği Bayraklarda "Aslan ve Güneş" Motifi

  <img src='img/w_32.jpg'/>
        
  Revan Hanlığı (1747-1828) Bayrağında "Aslan ve Güneş" Motifi
  
  ##### 13.İran İttihat Yönetimi Dönemi (2 Ağustos 1918-5 Aralık 1918)
  <img src='img/w_33.jpg'/>
        
  İran İttihat Hükumeti (2 Ağustos 1918 - 5 Aralık 1918) Bayrağında "Aslan ve Güneş" Motifi
  
  ##### 14. Türk Halılarında
  <img src='img/w_34.jpg'/>
        
  Türkmen Halılarında "Aslan ve Güneş" Motifi
  
  ##### 15. Banknotlar
  <img src='img/w_35.jpg'/>
        
  Türk devleti olan Özbekistan Cumhuriyeti Parasında "Aslan ve Güneş" Motifi
    
`,

  "akkordeon.images.headline": "resimler"
}