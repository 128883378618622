import Vue from 'vue'
import VueRouter from 'vue-router';
import "normalize.css";
import "./assets/scss/default.scss";
import "./assets/scss/site.scss";

import App from './App.vue'

Vue.config.productionTip = false

import router from './routing/router';
Vue.use(VueRouter);

new Vue({
  render: h => h(App),
  router
}).$mount('#app')
