export default {
  "intro.headline": `Willkommen auf der Website
  
  von Babek Peter Padar 
        
  (Babek Mirza Khan Kadjar)!`,
        
  "intro.text": `*(Französische Schreibweise: Kadjar; 
  englische Schreibweise: Qajar; deutsche Schreibweise: Kadschar; türkische Schreibweise: Kaçar).

  `,     
        
  "intro.picture1": `Ausbürgerungsurkunde aus der Iranischen Staatsbürgerschaft.

   `, 
    "intro.picture2": `Zeitungsartikel nach dem Raubüberfall vom 13. November 1989

   `,       
 

  "about.headline": `Über Babek Peter Padar`,
  "about.text": `
  Babek Peter Padar ist ein deutscher Investor und Nachfahre Seiner Kaiserlichen Majestät Fath-Ali Schah 
  Kadschar*, Kaiser von Iran.
  
  Er wurde am 11. Juni 1970 in Berlin (West) als Sohn seines Vaters Ramazan Padar (*1. Mai 1922 in Baku, 
  Aserbaidschan; +16. Januar 2007 in Berlin) und seiner Mutter Mahindokht Padar (*23. September 1947 in Ardebil, Iran) 
  geboren.
  
  Aufgrund politischer Konflikte wurde sein Vater vollständig enteignet und musste 1956 aus dem Iran nach 
  Deutschland ins Exil gehen. Er durfte lediglich 100 US-Dollar mit sich nehmen. Der Iran hatte ihn und 
  die gesamte Familie am 09. September 1980 aus der iranischen Staatsangehörigkeit ausgebürgert. Damit 
  gehört die Familie zu den wenigen, die der Iran jemals ausgebürgert hat. 
  
  In Zeiten des Kalten Krieges wuchs Babek Peter Padar als iranischer Staatsbürger in Berlin (West) auf. 
  Am 17. November 1980 nahm er die deutsche Staatsangehörigkeit an. Außer der deutschen 
  Staatsangehörigkeit besitzt er keine weiteren. Er spricht vier Sprachen (Deutsch, Englisch, Türkisch und 
  Persisch) und ist islamischen Glaubens. 
    
  `,

  "selfmade.headline": `Self Made Man - trotz großer Familie `,
  "selfmade.text": `
  Nach seiner Ankunft in Berlin (West) fing sein Vater sofort an als Geschäftsmann tätig zu sein und investierte später in Immobilien. Ramazan Padar wurde zu einem erfolgreichen Geschäftsmann auf dem Immobiliensektor. Er gab seinem Sohn schon früh essentielle Lektionen mit und lehrte ihn, mit Ehrgeiz und Fleiß seine Chancen bestmöglich zu nutzen. 
        
  Babek Peter Padar entwickelte schon in jungen Jahren großes Interesse und  Ehrgeiz in diesem Bereich. Im Alter von 12 Jahren verfasste er auf eigenen Wunsch für seinen Vater seinen ersten Mietvertrag. Dennoch begann er zunächst eine ganz eigene Karriere. 
  `,

  "juwelier.headline": `Der jüngste Juwelier Berlins`,
  "juwelier.text": `
  Im Jahr 1988 wurde Babek Peter Padar mit nur 18 Jahren jüngster Juwelier Berlins. Er 
  eröffnete ein Juweliergeschäft, obwohl er noch zur Schule ging. Ein Jahr zuvor war er als 
  Teilhaber bei einem Berliner Juwelier eingestiegen, trennte sich von diesem aber kurze Zeit 
  später wieder. Von 1989 bis 1991 führte er das Geschäft dann als alleiniger Inhaber.
  `,

  "wurzeln.headline": `Zurück zu den Wurzeln`,
  "wurzeln.text": `
  Zwischen 1994 und 2004 wurde Babek Peter Padar der Vermögensverwalter seines Vaters. 2004 
  begann er schließlich selbst in Immobilien und Firmenbeteiligungen zu investieren und ist 
  seither als Investor international erfolgreich. 
  `,

  // Akkordeon - Familie und Co
  "akkordeon.familie.headline": `Familie und Stammbaum`,
  "akkordeon.familie.text": `
  Babek Peter Padar ist mit Leyla Padar, geb. Tunç, verheiratet. Er lernte seine spätere Frau im März 1989 
  in Berlin (West) kennen. Die junge Frau wurde am 6. Oktober 1972 in Berlin (West) als türkische 
  Staatsbürgerin und als drittes Kind ihres Vaters Veli Tunç (*10. Juni 1941 in Varna, Bulgarien; +1.
  September 2004 in Corlu, Türkei) und ihrer Mutter Ayse Tunç, geb. Akdogan (*23. März 1944 in Misinli, 
  Türkei) geboren. Die beiden lernten sich in eben jenem Juweliergeschäft kennen, das Babek Peter Padar 
  gehörte. Leyla Tunç betrat als Kundin das Geschäft und dort stand er hinter den Vitrinen und beschloss,
  diese junge Frau zu seiner Ehefrau zu nehmen. Wenig später, am 8. Dezember 1989, heirateten beide 
  standesamtlich in Berlin (West). Die Hochzeitsfeier fand am 12. Januar 1990 im Schloss-Saal des Bristol 
  Hotel Kempinski (Kurfürstendamm Ecke Fasanenstraße) ebenfalls in Berlin (West) statt. Am 9. Juni 1994 
  nahm Leyla Padar die deutsche Staatsangehörigkeit an.
  
  Leyla und Babek Peter Padar haben zwei Kinder: einen Sohn, Serdar-Abbas Kevin Padar, geboren am 18.
  Oktober 1991 in Berlin, und eine Tochter, Isabelle Fatima-Soraya Padar, geboren am 24. November 1998 in 
  Berlin. Beide studieren in der deutschen Hauptstadt. Serdar-Abbas Kevin Padar studiert Wirtschaftsrecht 
  und Jura und Isabelle Fatima-Soraya Padar Business Marketing.
  `,

  // Akkordeon - Geschichte
  "akkordeon.geschichte.headline": `Die Kadscharen und ihre Bedeutung`,
  "akkordeon.geschichte.text": `
  131 Jahre lang waren sie die Kaiser von Iran: die Kadscharen, ein türkischer Stamm der Oghusen 
  (Untergruppe: Bozok, Unterstamm: Bayat, Unterarm: Dschalairiden). 
  
  Aus dem Stamm der Oghusen stammen ebenfalls die türkischen Herrscher der Seldschuken (Untergruppe: Üçok, Unterstamm: Kınık), der Afscharen (Untergruppe: Bozok, Unterstamm: Afschar) und die Osmanen (Untergruppe: Bozok, Unterstamm: Kayı) ab. Kadscharen und Osmanen sind somit miteinander verwandt. Sieben Kaiser der Kadscharen-Familie regierten den Iran zwischen 1794 und 1925. 
  
  #### Die Kaiser der Kadscharen-Dynastie
  
  1. Aga Mohammed Schah* (regierte von 1796 - 1797)
  2. Fath Ali Schah* (regierte von 1797 - 1834)
  3. Mohammed Schah* (regierte von 1834 - 1848)
  4. Nāser ad-Din Schah* (regierte von 1848 - 1896)
  5. Mozaffar ad-Din Schah * (regierte von 1896 - 1907)
  6. Mohammed Ali Schah* (regierte von 1907 - 1909)
  7. Ahmad Schah* (regierte von 1909 - 1925)
  
  Begründer der Dynastie war Aga Mohammed Khan** (später: Aga Mohammed Schah*), der über viele Jahre einen 
  Krieg führte und durch den der Staat mit den heutigen Grenzen des Iran entstand. 1796 wurde Aga Mohammed 
  Khan zum Kaiser von Iran und nannte sich fortan Aga Mohammed Schah* Kadschar. Seine Hauptstadt wurde 
  Teheran, damals noch ein Dorf ohne bedeutsame Geschichte.
  
  Unter ihm und seinem Nachfolger Kaiser Fath-Ali Schah* Kadschar, der sein Neffe war, wurde der unter den 
  Vorherrschern der Zand-Dynastie in der Auflösung befindliche Iran wieder vereint. Ohne die 
  Machtübernahme der Kadscharen wäre der Iran bereits im 18. Jahrhundert zerfallen und nicht existent, wie 
  wir ihn heute kennen. 
  
  *Schah (englisch: Shah) = Kaiser
  **Khan (alternative Schreibweise: Chan) = Fürst/Großfürst
  
  #### Irans Konstitutionelle Monarchie und Verfassung
  
  Unter dem Kaiser Mozaffar ad-Din Schah Kadschar vollzog der Iran als erstes islamisches Land den Wandel 
  von der absoluten zur konstitutionellen Monarchie. 
        
  Der Kaiser, der reformfreudiger war als seine Vorgänger, unterzeichnete die Verfassung, welche am 30.
  Dezember 1906 in Kraft trat. 
        
  Der damalige Kaiser Mozaffar ad-Din Schah Kadschar stimmte 1906 Wahlen zu einem Parlament (Madschles)
  zu. Dieses verabschiedete dann eine Verfassung (Qanun-e Asasi) mit bürgerlichen Grundrechten. So wurde 
  der Iran, als erstes islamisches Land überhaupt zu einer Demokratie, das sich ein gewähltes Parlament 
  und eine Verfassung gab. 
        
  Nach dem politischen Wandel nahmen die bis dahin bereits erheblichen Einflüsse kolonialer Mächte wie 
  Großbritannien und Russland weiter zu. 
        
  1908 wurden die ersten Ölvorkommen im Iran entdeckt. Dieser Fund erwies sich als schwerer 
  Schicksalsschlag, der das Land bis heute nicht zur Ruhe kommen lässt. 
        
  Weil die junge Demokratie für die Kolonialmächte ein Hindernis zur Ausbeutung des Öls darstellte, wurde 
  ein von den Briten hektisch ausgearbeiteter Plan erstellt. Ziel war es, das Land zu unterwerfen, um es 
  dann auszubeuten. 
        
  Die türkisch-stämmigen Volksgruppen aus dem Iran traten intensiver in ein Kooperationsverhältnis mit der 
  Türkei ein, um die Kolonialmächte in dieser Region gemeinsam zu bekämpfen. Die Briten waren durch die 
  aufblühende Dominanz der Völker dieser Region, die sie eigentlich ausbeuten wollten, sehr besorgt. Sie 
  wollten nicht, dass diese beiden Länder eine Einheit bilden. Man wollte eine Reichsbildung in den 
  muslimischen Ländern unbedingt verhindern. Mehrere kleine und schwache Nationalstaaten hingegen konnte 
  man viel bequemer ausbeuten. Man musste nur Unfrieden unter den Völkern dieser Region sähen.

  Unter den Kadscharen war der Iran eine liberale, multikulturelle und multiethnische Gesellschaft ohne 
  jegliche Diskriminierung. 
        
  Die Arier-Theorie war Anfang des 20. Jahrhunderts in Europa bereits bekannt und bot sich hier als eine 
  gute Möglichkeit, die Völker untereinander mit Hass und Verachtung zu spalten. Dazu wurden schließlich 
  durch die Briten altpersische Mythologien herangezogen, die es in ihrer Bedeutung so nie gab, um den 
  Pan-Iranismus zu erfinden. Der Pan-Iranismus hat keinerlei Ähnlichkeit oder Verbindung mit der im Iran 
  lebenden Bevölkerung. Ähnliche Theorien wie etwa den Pan-Arabismus, hatten die Briten bereits 
  erfolgreich bei der Zerschlagung des multikulturellen Osmanischen Reichs angewendet. Da der Iran 
  ebenfalls ein multikulturelles Land war, dessen Völker friedlich zusammenlebten, baute man auf das 
  gleiche Prinzip für die Zerschlagung. 

  Aus diesen Gründen erkannten die Briten, dass nur ein nicht-türkischstämmiger Vasall ihre Pläne für 
  einen Umsturz und zur Unterwerfung des Iran unterstützen würde. 
        
  Der britische Geheimdienst baute dann Reza, einen unhöflichen, Analphabeten und ehemaligen Stalljungen 
  der Kadscharen, der bei den Kosaken zu einem Kommandeur aufgestiegen war, zu deren Nachfolger auf.
  Großbritannien plante, durch Reza einen gehorsamen iranischen Staat zu errichten. 
        
  Mit einem durch Großbritannien organisierten Militärputsch beendete Reza 1925 schließlich gewaltsam die 
  junge Demokratie und zwang das Parlament unter Gewaltandrohung ihn zum absolutistischen Herrscher zu 
  ernennen. Der Pahlavi-Spitzname wurde Reza vom britischen Agenten Ardeshir Reporter gegeben. 
        
  Viele hochrangige Mitglieder der Kadscharen flohen ins Ausland, gingen in den Untergrund oder wurden 
  verhaftet. Einige Kadscharen mit geringem Rang arrangierten sich hingegen mit Reza Pahlavi, da sie sich 
  finanzielle und soziale Vorteile erhofften, die sie aufgrund ihres geringen Ranges unter dem Kaiser 
  Ahmad Schah Kadschar nicht bekommen hätten. Der Kaiser Ahmad Schah Kadschar, der letzte legitime Kaiser 
  von Iran, ging ins Exil nach Frankreich. Die Dynastie seines illegitimen Nachfolgers Reza, der sich 
  inzwischen Reza Schah Pahlavi, "Licht der Arier", nannte, erkannte er nie an. 

  Nach der Machtübernahme Adolf Hitlers wechselte Reza, der ein bekennender Faschist und Anti-Demokrat 
  war, die Seiten und arbeitete fortan mit Adolf Hitler gegen die Briten. Während des Zweiten Weltkriegs 
  belieferte er die deutsche Kriegsmaschinerie mit Erdöl.
        
  Reza Pahlavi wurde dann wegen seiner Unterstützung Adolf Hitlers selbst von Großbritannien gestürzt und 
  nach Südafrika verbannt, wo er schließlich verstarb. Auch sein Sohn Mohammad Reza Pahlavi, der sich 
  Mohammad Reza Schah, "Licht der Arier", nannte und ebenfalls ein grausamer Diktator war, wurde 
  1979 durch das Volk verjagt und floh nach Ägypten. Auch er starb, wie sein Vater, in Afrika und wurde 
  dort begraben. 
      
  #### Von Teheran nach Berlin - Exil seines Vaters
        
  Wegen Reza Pahlavis Hitler-Nähe wurde der Iran im 2. Weltkrieg schließlich durch die Sowjetunion und 
  Großbritannien besetzt, wo wegen der faschistischen Pahlavi-Diktatur interne Konflikte und 
  separatistische Kämpfe tobten. 
      
  Der Vater von Babek Peter Padar, Ramazan Padar, wurde am 1. Mai 1922 in Baku, Aserbaidschan, als
  iranischer Staatsangehöriger geboren. Zuvor waren die Eltern von Ramazan Padar aus dem Iran nach Baku
  gezogen, um dort beim Aufbau der Erdölindustrie behilflich zu sein.
  
  Die Demokratische Republik Aserbaidschan wurde 1918, nach dem Zerfall des Russischen Reiches, gegründet. 
  Sie war ein völkerrechtlich anerkannter souveräner Staat mit der Hauptstadt Baku und wurde 1920 von den 
  Sowjets besetzt und völkerrechtswidrig annektiert.
  
  Da die Familie iranische Staatsangehörige und Aristokraten waren, wurden sie vollständig enteignet und 
  1938 vom Sowjetischen Geheimdienst NKWD verhaftet und in einer Nacht-und-Nebel-Aktion in den Iran 
  abgeschoben. Dort bekam die Familie - unter Protest - den willkürlich ausgesuchten Familiennamen Padar. 
  Padar ist eine geografische Region in Aserbaidschan. Als die Familie sich weigerte diesen Namen 
  anzunehmen, wurde ihnen mit Inhaftierung gedroht.
  
  Auch Ramazan Padar kämpfte später im Iran um die iranische Souveränität und gegen die Pahlavi-Diktatur. 
  In den 1950er Jahren war Mohammad Mossadegh (ebenfalls ein Nachkomme der Kadscharen) vom Volk gewählter 
  Premierminister von Iran und Mitgründer der Nationalen Front. Der Streit um den Diebstahl des iranischen 
  Öls durch die Briten entwickelte sich zu einer internationalen Krise, die mit der Verstaatlichung des 
  iranischen Öls durch Premierminister Mohammad Mossadegh einen Höhepunkt erreichte. 
  
  Mit der Operation Ajax von 1953 wurde durch die CIA die vom Volk gewählte Regierung des Premierministers 
  Mohammad Mossadegh schließlich gestürzt und das Regime der Pahlavi-Diktatur von westlichen 
  Geheimdiensten endgültig etabliert.
  
  Aus Gründen der anhaltenden Verfolgung und nach vorausgegangener erneuter vollständiger Enteignung der 
  gesamten Familie - diesmal durch die Pahlavi-Diktatur - ging Ramazan Padar 1956 mit einem verbliebenen 
  Kapital von 100 US-Dollar ins Exil nach Berlin (West), wo sein älterer Bruder bereits lebte.
  `,

  "akkordeon.residenzen.headline": `Residenzen, Kunst und Familienbesitz `,
  "akkordeon.residenzen.text": `
  Die kaiserliche Familie brachte viele Kunstliebhaber mit sich. Mehr als 60 Paläste, unzählige Kronjuwelen 
  und Schätze sind aus der Zeit der Kadscharen erhalten. Hier findet sich eine kleine Auswahl.  

  #### Das Wappen der Kadscharen
  
  Das Wappen der Kadscharen-Familien ist geprägt durch den Löwen und die Sonne. Der Löwe ist das nationale 
  Wappentier des Iran, die aufgehende Sonne ein Symbol für den schiitischen Islam.
  
  
  #### Kaiserliche Paläste im Iran: Kulturelles Erbe
    
  ##### Der Golestan-Palast
  
  Der Regierungspalast der Kadscharen wurde um die Jahrhundertwende vom 18. zum 19. Jahrhundert in Teheran 
  errichtet. Seit 2013 ist der Golestan-Palast UNESCO-Weltkulturerbe. 
  
  Tahmasp I aus der Linie der Safawiden begann mit dem Bau der Zitadelle, die jedoch erst unter Aga 
  Mohammed Khan als offizielle Residenz der Kadscharen ihre Bedeutung erhielt. Die Architektur und 
  Gestaltung des Palastes wurde von den Mitgliedern der Dynastie stets weiterentwickelt. Heute ist der 
  Palast noch 4,5 Hektar groß und kann besichtigt werden.
    
  ##### Palast-Komplex von Saadabad
  
  Der Palastkomplex von Saadabad umfasst 18 Paläste und rund 110 Hektar Land. Dies war die Sommerresidenz 
  der kadscharischen Kaiser. Zu den Besonderheiten gehören hier der größte (Weißer) Palast und der älteste 
  (Grüner) Palast der Gebäude sowie das Museum der Schönen Künste. 
  `,
        // Akkordeon - Wappen
  "akkordeon.wappen.headline": `DAS MOTIV „LÖWE UND SONNE“
  
   ODER IKONE IN DER TÜRKISCHEN ZIVILISATION
`, 
        
   "akkordeon.wappen.text":`
  Das „Löwe und Sonne“ -Motiv auf der iranischen Flagge in der Pahlavi-Zeit soll nach einem weit verbreiteten Missverständnis nicht mit den Turkvölkern verwandt sein. Wenn wir jedoch die historischen Daten untersuchen, sehen wir, dass dies nicht der Fall ist und dass dieses Motiv zur Zivilisation der Turkvölker und Kultur gehört. Wir begegnen häufig dem „Löwe und Sonne“-Motiv und -Symbol auf Münzen, Banknoten, Grabsteinen, architektonischen Werken, Kelchen, Glasarbeiten, Ziegeln, Fahnen und anderen Objekten und Artefakten aus vielen Perioden in der Geschichte der Turkvölker, einschließlich der türkischen Mythologie. 

  Zu keiner Zeit kann das „Löwe und Sonne“ -Motiv der persischen Kultur oder der zoroastrischen Religion zugeordnet werden. Weder in Persepolis noch in Yazd oder in anderen Hochburgen der persischen Kultur findet man das „Löwe und Sonne“ -Motiv.
        
  Tatsächlich vertrat der persisch-nationalistische Instrumentalist Muctaba Minevi, der sich des türkischen Ursprungs des „Löwe und Sonne“ -Motivs bewusst war, die Ansicht, dass dieses Motiv aus der iranischen Kultur eliminiert werden sollte.* 
   
  *(Quelle: Muctabâ Mînevî, Târîh ve Ferheng, Çâp: 3, Şirket-i Sahâmî-yi İntişârât-ı Hârezmî, Tahran, h. ş. 1369, s. 352, 359, 374, 378.)
        
  Wir können die Namen der historischen türkischen Staaten nennen, in denen das „Löwe und Sonne“ -Motiv bereits existierte:
        
  Kuschana Imperium (30-375), Turk-Schahi-Dynastie (665-850), Ghaznawiden-Dynastie (963-1186), Seldschuken-Reich (1037-1194), Reich der Rum-Seldschuken (1077-1307), Choresm-Schah-Dynastie (1077-1231), ägyptische Mamluken (1250-1517), Ilchane (1256-1336), Osmanen (1299-1922), Dschalairiden (1336-1432), Timuriden (1370-1507), Aq Qoyunlu (1378-1508), Qara Qoyunlu (1380-1469), Vijayapura (1490-1686), Safawiden (1501-1736), Mogulreich (1526-1858), Qutb-Schāhī-Sultanat (1512-1687), Afschariden (1736-1796), Kadscharen (1779 -1925), Khanat Nachitschewan (1747-1828), Khanat Jerewan (1747-1828), Regierung der Iranischen Union (2. August 1918 - 5. Dezember 1918) usw.
        
  In Anbetracht der folgenden historischen Chronologie geben wir Beispiele für Objekte mit dem Motiv oder Symbol „Löwe und Sonne“.
        
  ##### 1. Turk-Schahi-Periode (665-850)
  <img src='img/w_01.jpg'/>

  Das „Löwe und Sonne“ -Motiv in den Münzen der Turk-Schahi-Periode
        
  ##### 2. Seldschuken-Periode
  <img src='img/w_02.jpg'/>
        
  Das „Löwe und Sonne“ -Motiv auf der Münze aus der Seldschuken-Periode (1037-1194)
        
  <img src='img/w_03.jpg'/>
        
  Das „Löwe und Sonne“ -Motiv der Zeit Seldschuken-Periode
        
  <img src='img/w_04.jpg'/>
        
  Das „Löwe und Sonne“ -Motiv in den Werken der Rum-Seldschuken
        
  <img src='img/w_05.jpg'/>
        
  Münze der seldschukischen Kai Chosrau I.-Periode mit dem Motiv „Löwe und Sonne“ (1240)

  ##### 3. Choresm-Schah-Periode (1077-1231)
  <img src='img/w_06.jpg'/>
        
  Das „Löwe und Sonne“ -Motiv auf einer Münze aus der Dschalal ad-Din Choresm-Schah-Periode
  
  ##### 4. Periode der Ilchane (1256-1336)
  <img src='img/w_07.jpg'/>
        
  „Löwe und Sonne“ -Motiv auf Münzen aus der Periode der Ilchane
  
  <img src='img/w_08.jpg'/>
        
  „Löwe und Sonne“ -Motiv in Keramiken aus der Periode der Ilchane
  
  ##### 5. Timuridenzeit (1370-1507)
  <img src='img/w_09.jpg'/>
        
  Auf der Flagge der Timuridenzeit / Auf dem Schlepper Motiv „Löwe und Sonne“ (1423)
  
  <img src='img/w_10.jpg'/>
        
  „Löwe und Sonne“ -Motiv an der Ulugh-Beg-Madrasa in Samarkand, Usbekistan

  ##### 6. Osmanische Zeit (1299-1922)
  <img src='img/w_11.jpg'/>
        
  Das „Löwe und Sonne“ -Motiv auf dem Gedenkabzeichen zu Ehren des Çanakkale-Sieges.
  
  ##### 7. Safawiden Zeit (1501-1736)
  <img src='img/w_12.jpg'/>
        
  Das Motiv „Löwe und Sonne“ auf der Flagge aus der Safawiden Zeit
  
  <img src='img/w_13.jpg'/>
        
  Das Motiv „Löwe und Sonne“ auf der Safawiden-Flagge / Schlepper in dem Gemälde der Expedition des Safawiden-Botschafters Mohammad Reza Beg nach Paris
  
  ##### 8. Das Mogulreich (1526-1858)
  <img src='img/w_14.jpg' />
        
  Das „Löwe und Sonne“ -Motiv auf der Mogul-Flagge
  
  <img src='img/w_15.jpg' />
        
  Das „Löwe und Sonne“ -Motiv auf einer anderen Mogul-Flagge
  
  <img src='img/w_16.jpg' />
        
  Eine Münze aus der Periode des Mogul Herrschers Jahangir Shah mit dem Motiv „Löwe und Sonne“.

  <img src='img/w_17.jpg' />
        
  Eine weitere Münze aus der Periode des Mogul Herrschers Jahangir Shah mit dem Motiv „Löwe und Sonne“.

  ##### 9. Qutb-Schāhī-Sultanat (1518-1687)
  <img src='img/w_18.jpg'/>
        
  Das „Löwe und Sonne“ Motiv im Qutb-Schāhī-Sultanat 
  
  ##### 10. Afschariden Periode (1736-1796)
  <img src='img/w_19.jpg'/>
        
  Das „Löwe und Sonne“ -Motiv auf der Flagge der Afschariden
  
  <img src='img/w_20.jpg'/>
        
  Das „Löwe und Sonne“ -Motiv in dem Siegel von Nadir Shah Afschar (1746)
  
  ##### 11. Die Kadscharen (1779-1925)
  <img src='img/w_21.jpg'/>
        
  Das „Löwe und Sonne“ -Motiv auf der Flagge der Kadscharen aus der Zeit von Aga Mohammed Schah Kadschar
  
  <img src='img/w_22.jpg'/>
        
  Das „Löwe und Sonne“ -Motiv auf den Flaggen aus der Periode von Fath Ali Schah Kadschar

  <img src='img/w_23.jpg'/>
        
  Das „Löwe und Sonne“ -Motiv auf Münzen aus der Periode von Fath Ali Schah Kadschar (1830) 
  
  <img src='img/w_24.jpg'/>
        
  Das „Löwe und Sonne“ -Motiv auf der Flagge der Mohammed Schah Kadschar-Ära

 <img src='img/w_25.jpg'/>
        
  Das „Löwe und Sonne“ -Motiv auf der Flagge von Nāser ad-Din Schah Kadschar und späteren Perioden

  <img src='img/w_26.jpg'/>
        
  Das Motiv „Löwe und Sonne“ auf einer Briefmarke aus der Zeit von Mohammed Ali Schah Kadschar (1906)

  <img src='img/w_27.jpg'/>
        
  Auf der Silbermünze aus der Ahmad Schah Kadschar Periode mit dem Motiv „Löwe und Sonne“.

  <img src='img/w_28.jpg'/>
        
  Das Hoheitszeichen des Kadscharen-Reichs 
  
  <img src='img/w_29.jpg'/>
        
  Das Motiv „Löwe und Sonne“ in der Zeitung Vakayi-i İttifakiye während der Kadscharen-Zeit
  
  ##### 12. Khanate der Kadscharen
  <img src='img/w_30.jpg'/>
        
  Das „Löwe und Sonne“ -Motiv auf den Flaggen, die Abbas Mirza Kadschar dem Khanat Nachitschewan (1747-1828) überreichte

  <img src='img/w_31.jpg'/>
        
  Das „Löwe und Sonne“ -Motiv auf den Flaggen, die Abbas Mirza Kadschar dem Khanat Nachitschewan (1747-1828) überreichte

  <img src='img/w_32.jpg'/>
        
  Das „Löwe und Sonne“ -Motiv auf der Flagge des Khanat Jerewan (1747-1828)
  
  ##### 13. Die Verwaltungsperiode der Iranischen Union (2. August 1918 - 5. Dezember 1918)
  <img src='img/w_33.jpg'/>
        
  Das Motiv „Löwe und Sonne“ auf der Flagge der Regierung der Iranischen Union (2. August 1918 - 5. Dezember 1918)
  
  ##### 14. In türkischen Teppichen
  <img src='img/w_34.jpg'/>
        
  „Löwe und Sonne“ -Motiv auf turkmenischen Teppichen
  
  ##### 15. Banknoten
  <img src='img/w_35.jpg'/>
        
  Das Motiv „Löwe und Sonne“ auf der Banknote der Republik Usbekistan
    
`,
  
  "akkordeon.images.headline": "Bilder"
}