<template>
  <div data-collapse="all" data-animation="default" data-duration="400" role="banner" class="navbar w-nav"
       style="direction: ltr">
    <div class="float-x">
      <a href="index.html" class="brand w-nav-brand">
        <img src="img/wappen.png"
             loading="lazy"
             width="66" height="47" alt="" class="image-4">
      </a>
      <div class="language-chooser">
        <img
            :src="`img/flags/${currentLocale}.png`"
            :alt="currentLocale"
            @click="chooserVisible = !chooserVisible"
        />

        <div class="selector" :class="chooserVisible?'open':''">
          <img v-for="f in availFlags" :key="f.flag"
               :src="`img/flags/${f.flag}`"
               :alt="f.description"
               @click="applyNewLocale(f.key); chooserVisible = false"
          />

        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {localSetter, localGetter} from "../config/CurrentLocale";

export default {
  name: "TheMenu",
  data() {
    return {
      flags: [
        {
          flag: "ger.png",
          key: "ger",
          description: "GER"
        },
        {
          flag: "eng.png",
          key: "eng",
          description: "ENG"
        },
        {
          flag: "fra.png",
          key: "fra",
          description: "FRA"
        },
        {
          flag: "rus.png",
          key: "rus",
          description: "RUS"
        },
        {
          flag: "far.png",
          key: "far",
          description: "FAR"
        },
        {
          flag: "tur.png",
          key: "tur",
          description: "TUR"
        },
        {
          flag: "aze.png",
          key: "aze",
          description: "AZE"
        },
      ],
      chooserVisible: false
    }
  },
  computed: {
    ...localGetter,
    availFlags() {
      return this.flags.filter(e => e.key !== this.currentLocale);
    }
  },
  watch: {
    '$route.query.l': {
      handler: function (val) {
        this.setLocale(val);
      },
      immediate: true
    }
  },
  methods: {
    ...localSetter,
    applyNewLocale(newLocale) {
      this.$router.replace({query: {l: newLocale}});
      this.setLocale(newLocale);
    }
  }
}
</script>

<style lang="scss" scoped>
.float-x {
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  align-items: flex-end;
}

.language-chooser {
  padding-left: 5px;
  padding-bottom: 6px;
  position: relative;

  img {
    margin-left: 10px;
    margin-right: 10px;
    width: 35px;
    cursor: pointer;
  }

  .selector {
    background-color: white;
    position: absolute;

    overflow: hidden;

    * {
      margin-top: 1rem;
    }

    > img {
      cursor: pointer;
      opacity: .5;
      transition: opacity .1s;

      &:hover {
        opacity: 1;
      }
    }

    max-height: 0;
    padding-bottom: 10px;
    transition: max-height .3s;

    &.open {
      max-height: 350px;
    }

    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
  }
}
</style>