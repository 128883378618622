export default {
  "intro.headline": `Bienvenue sur le site Web
  
  de Babek Peter Padar!`,
 "intro.text": `
  `,
         "intro.picture1": `Certificat d'expatriation de la nationalité iranienne.

   `, 
    "intro.picture2": `Article de journal suite au braquage du 13 novembre 1989

   `,       
        
  "about.headline": `À propos de Babek Peter Padar`,
  "about.text": `
  Babek Peter Padar est un investisseur allemand et un descendant de Sa Majesté Impériale, Fath Ali Chah Kadjar, empereur d'Iran.

  Il est né le 11 juin 1970 à Berlin-Ouest, et est le fils de Ramazan Padar (né le 1er mai 1922 à Bakou, en Azerbaïdjan, décédé le 16 janvier 2007 à Berlin) et de Mahindokht Padar (née le 23 septembre 1947 à Ardabil, en Iran).

  En raison de conflits politiques, son père avait été entièrement exproprié. Contraint à l'exil, il avait dû quitter l'Iran et trouver refuge en Allemagne en 1956. Il avait été autorisé à emporter 100 dollars américains seulement. Le 9 septembre 1980, l'Iran décide de le déchoir de sa nationalité avec toute sa famille. Sa famille fait partie des rares personnes à avoir été expatriées d'Iran.

  Pendant la guerre froide, Babek Peter Padar a grandi à Berlin-Ouest en tant que citoyen iranien. Le 17 novembre 1980, il a été naturalisé allemand. Il ne possède aucune autre nationalité. Il parle quatre langues (allemand, anglais, turc et persan) et il est de confession musulmane.
  `,

  "selfmade.headline": `Autodidacte malgré une famille nombreuse`,
  "selfmade.text": `
  Après être arrivé à Berlin-Ouest, son père a commencé à travailler en tant qu'homme d'affaires et a ensuite investi dans l'immobilier. Au fil du temps, Ramazan Padar est devenu un homme d'affaires couronné de succès dans l'immobilier. Il a transmis les connaissances essentielles à son jeune fils et lui a appris comment tirer parti de ses opportunités avec ambition et en travaillant dur.

  Dès son plus jeune âge, Babek Peter Padar a développé un grand intérêt et une forte ambition dans ce domaine. À l'âge de 12 ans, il a conclu son premier accord de location pour son père, à sa propre demande. Néanmoins, il a commencé sa propre carrière.
`,

  "juwelier.headline": `Le plus jeune bijoutier de Berlin`,
  "juwelier.text": `
  En 1988, à 18 ans seulement, Babek Peter Padar est devenu le plus jeune bijoutier de Berlin. Il a ouvert sa bijouterie, tout en poursuivant ses études. Un an auparavant, il avait noué un partenariat avec un autre bijoutier de Berlin, partenariat qu'il a dissous peu de temps après. Il a dirigé l'entreprise en tant qu'unique propriétaire entre 1989 et 1991.
  `,

  "wurzeln.headline": `Retour aux sources`,
  "wurzeln.text": `
  Entre 1994 et 2004, Babek Peter Padar est devenu le gestionnaire du patrimoine de son père. En 2004, il a commencé à investir dans l'immobilier et dans les sociétés financières et, depuis lors, est devenu un investisseur à succès à l'international.
  `,

  // Akkordeon - Familie und Co
  "akkordeon.familie.headline": `Famille et arbre généalogique`,
  "akkordeon.familie.text": `
  Babek Peter Padar est marié à Leyla Padar, née Tunç. Il a rencontré son épouse en mars 1989 à Berlin-Ouest. La jeune femme, troisième enfant de Veli Tunç (né le 10 juin 1941 à Varna, en Bulgarie et décédé le 1er septembre 2004 à Corlu, en Turquie) et de Ayse Tunç, née Akdogan (née le 23 mars 1944 à Misinli, en Turquie), est née à Berlin-Ouest le 6 octobre 1972 en tant que citoyenne turque. Ils se sont rencontrés pour la première fois dans sa bijouterie. Lorsque Leyla Tunç est entrée dans la boutique, il l'a observée derrière les vitrines et a immédiatement décidé de la prendre pour épouse. Il a servi sa cliente six fois pendant cinq minutes avant que Babek Peter Padar, alors âgé de dix-huit ans, ne la demande en mariage. Leyla Tunç, âgée de 16 ans, a accepté immédiatement. Peu de temps après, le 8 décembre 1989, ils ont célébré leur mariage civil à Berlin-Ouest. La célébration du mariage s'est tenue dans la salle de bal « Schloss-Saal » du Bristol Hotel Kempinski (Kurfürstendamm/Fasanenstrasse) le 12 janvier 1990. Le 9 juin 1994, Leyla Padar a obtenu la nationalité allemande.

  Leyla et Babek Peter Padar ont deux enfants qui sont nés à Berlin : son fils Serdar-Abbas Kevin, né le 18 octobre 1991, et sa fille Isabelle Fatima-Soraya, née le 24 novembre 1998. Tous deux étudient respectivement le droit et la gestion d'entreprise dans la capitale allemande. 
  `,

  // Akkordeon - Geschichte
  "akkordeon.geschichte.headline": `Les Kadjars et leur importance`,
  "akkordeon.geschichte.text": `
  Pendant 131 ans, les empereurs d'Iran étaient issus d'une même lignée : les Kadjars, une tribu turque originaire d'Oghouze (sous-groupe : Bozok, sous-tribu : Bayat, dynastie : Djalayirides). 

  Les Seldjoukides turcs (sous-groupe : Üçok, sous-tribu : Kınık), ainsi que les Afcharides (sous-groupe : Bozok, sous-tribu : Afchars) et les Ottomans (sous-groupe : Bozok, sous-tribu : Kayı) étaient les descendants de la tribu des Oghuz. Par conséquent, les Kadjars et les Ottomans sont des tribus parallèles.

  Sept empereurs de la famille Kadjar ont régné sur l'Iran entre 1794 et 1925.

  
  #### Les empereurs de la dynastie Kadjars 
  
  1. Agha Mohammad Chah Kadjar (a régné entre 1796 et 1797)
  2. Fath Ali Chah Kadjar (a régné entre 1797 et 1834)
  3. Mohammad Chah Kadjar (a régné entre 1834 et 1848)
  4. Nassereddine Chah Kadjar (a régné entre 1848 et 1896)
  5. Mozaffareddine Chah Kadjar (a régné entre 1896 et 1907)
  6. Mohammad Ali Chah Kadjar (a régné entre 1907 et 1909)
  7. Ahmad Chah Kadjar (a régné entre 1909 et 1925)


  Le fondateur de la dynastie était Agha Mohammad Khan Kadjar (connu plus tard comme l'empereur Agha Mohammad Chah Kadjar), qui a mené des guerres pendant de nombreuses années et grâce à qui les frontières actuelles de l'Iran ont vu le jour. En 1796, Agha Mohammad Khan Kadjar est devenu l'empereur d'Iran et a ensuite pris le nom Agha Mohammad Chah Kadjar. Sa capitale était Téhéran, un village sans la moindre importance historique à l'époque.

  Sous son égide et celle de son successeur et neveu, l'empereur Fath Ali Chah Kadjar, l'Iran, qui était sur le point de se disloquer sous le règne de la dynastie des Zand, s'est réunifié. Sans la prise de pouvoir des Kadjars, le pays aurait cessé d'exister dès le 18e siècle et ne serait pas l'Iran tel que nous le connaissons aujourd'hui.

  
  #### La monarchie constitutionnelle en Iran
  
  Sous le règne de l'empereur Mozaffareddine Chah Kadjar, l'Iran fut le premier état islamique à passer d'une monarchie absolue à une monarchie constitutionnelle. 
  
  L'empereur, qui était plus réformateur que ses prédécesseurs, a signé la Constitution, qui est entrée en vigueur le 30 décembre 1906.
  
  L'empereur de l'époque, Mozaffareddine Chah Kadjar, a accepté d'élire un parlement (Madjles) en 1906, qui a alors adopté une Constitution (Qanun-e Asasi) contenant les droits civils fondamentaux. Par conséquent, l'Iran fut le premier état islamique à devenir une démocratie avec un parlement élu et une Constitution. 

  Après ce changement politique, la forte influence dominante des puissances coloniales de la Grande-Bretagne et de la Russie a augmenté. 

  Les premiers gisements de pétrole ont été découverts en Iran en 1908. 
  
  Cette découverte fut un terrible coup du sort, car cela n'a pas apporté la paix au pays. 

  Puisque cette démocratie naissante était une épine dans le pied des puissances coloniales dans leurs efforts pour exploiter le pétrole iranien, la Grande-Bretagne a frénétiquement élaboré un plan pour forcer le pays à se soumettre. 

  Afin de résister ensemble aux puissances coloniales, les personnes d'origine turque qui vivaient en Iran ont noué une coopération plus forte avec la Turquie. La domination grandissante des habitants de la région qu'elle voulait exploiter a effrayé la Grande-Bretagne. Elle ne voulait pas que ces deux pays s'unifient pour former un grand empire doté de structures fédérales, car cela aurait donné naissance à une nouvelle superpuissance plus puissante que l'Empire ottoman récemment dissous.

  Il fallait empêcher la formation d'un nouvel empire de pays musulmans par tous les moyens possibles, car il serait beaucoup plus facile d'exploiter plusieurs petits États-nations faibles en semant la discorde parmi les populations locales. 

  Sous le régime des Kadjars, l'Iran était une société libérale, multiculturelle et multiethnique sans discrimination.

  La théorie aryenne était déjà connue en Europe au début du 20e siècle et offrait une bonne occasion de diviser les locaux en répandant la haine et le mépris. La Grande-Bretagne a utilisé des aspects de l'ancienne mythologie perse, qui n'a jamais eu ce sens, pour inventer le paniranisme. Il n'existe aucune similitude ni lien entre le paniranisme et la culture iranienne. Des théories similaires, comme le panarabisme, avaient été utilisées avec succès pour détruire l'Empire ottoman et son multiculturalisme. Puisque l'Iran était également un pays multiculturel, dont les habitants cohabitaient pacifiquement, des projets suivant le même programme de destruction ont été élaborés.

  Pour ces raisons, la Grande-Bretagne a compris que seuls des vassaux d'origine non-turque soutiendraient leur projet de soumission.

  Les services secrets britanniques ont promu Reza, un homme grossier et analphabète, ancien garçon d'écurie des Kadjars, qui s'est élevé au rang de commandant des cosaques, comme successeur. La Grande-Bretagne prévoyait d'établir un état vassal iranien obéissant grâce à Reza. 

  Par un coup d'État militaire orchestré par la Grande-Bretagne, Reza a mis fin à la jeune démocratie par la force en 1925 et, par la menace de la violence, a également forcé le parlement à le nommer comme dirigeant absolu. Le surnom Pahlavi a été donné à Reza par l'agent britannique Ardeshir Reporter. 

  De nombreux membres haut placés dans la dynastie Kadjar ont fui à l'étranger, ont disparu de la circulation ou ont été arrêtés. D'autre part, certains subalternes Kadjars ont collaboré avec Reza Pahlavi, dans l'espoir d'en tirer des avantages financiers et sociaux auxquels ils n'auraient pas pu prétendre (de par leur faible rang) sous le règne de l'empereur Ahmad Chah Kadjar. L'empereur Ahmad Chah Kadjar, dernier empereur légitime d'Iran, qui se trouvait en France pour un traitement médical pendant le coup d'État, n'est jamais revenu. Ce dernier n'a jamais reconnu la « Lumière des Aryens », la dynastie de Reza, le successeur illégitime qui s'est ensuite fait appeler Reza Chah Pahlavi. 

  Après la prise de pouvoir par Hitler, Reza, qui était un fasciste et un antidémocrate avéré, a changé de camp et a collaboré avec le régime nazi contre la Grande-Bretagne. Pendant la Seconde Guerre mondiale, il a fourni du pétrole aux machines de guerre allemandes.

  En raison de son soutien envers Hitler, Reza Pahlavi a été renversé par la Grande-Bretagne et exilé en Afrique du Sud, où il est décédé trois ans plus tard. Son fils, Mohammad Reza Pahlavi, qui se faisait également appeler « Lumière des Aryens », était un dictateur impitoyable et, comme son père, a été renversé par le peuple en 1979, avant de fuir vers l'Égypte. Tout comme son père, il est mort en Afrique et y a été inhumé.

      
  #### De Téhéran à Berlin, l'exil de son père
        
  En raison de la proximité entre Reza Pahlavi et Hitler, l'Iran a été occupé par l'Union soviétique et la Grande-Bretagne. Les conflits internes et le séparatisme faisaient rage en raison de sa dictature fasciste. 
      
  Ramazan Padar, le père de Babek Peter Padar, citoyen iranien, est né le 1er mai 1922 à Bakou, en Azerbaïdjan. Avant cela, les parents de Ramazan Padar avaient quitté l'Iran pour s'installer à Bakou, afin de participer à la construction de l'industrie pétrolière locale.
  
  La République démocratique d'Azerbaïdjan a été fondée en 1918 après la dislocation de l'empire russe. Reconnue par le droit international en tant qu'état souverain ayant Bakou comme capitale, elle a été occupée et annexée de manière contraire au droit international par les Soviétiques en 1920.

  Puisque les membres de la famille étaient des citoyens iraniens et des aristocrates, ils ont été privés de tous leurs biens et arrêtés en 1938 par le NKWD, les services secrets soviétiques, dans le cadre d'une opération top secrète et déportés en Iran. Là-bas, la famille s'est vu attribuer contre son gré le nom de famille Padar, choisi arbitrairement. Padar est une région d'Azerbaïdjan. Lorsque la famille a refusé de prendre ce nom, elle a été menacée d'arrestation.

  De plus, Ramazan Padar a combattu en Iran, non seulement pour la souveraineté de son pays, mais aussi contre la dictature des Pahlavi. Dans les années 1950, Mohammad Mossadegh (également descendant des Kadjars) a été élu par le peuple au poste de Premier ministre d'Iran et a fait partie des fondateurs de la Frontière nationale. Le conflit lié au vol du pétrole iranien par la Grande-Bretagne s'est transformé en une crise internationale, qui a atteint son paroxysme avec la nationalisation du pétrole iranien par le Premier ministre Mohammad Mossadegh. 

  Avec l'opération Ajax en 1953, la CIA a fini par renverser le gouvernement du Premier ministre Mohammad Mossadegh, qui avait été élu par le peuple. Par conséquent, le régime du dictateur Pahlavi a été durablement établi par les services secrets occidentaux.

  En raison de la persécution incessante et de la deuxième privation de tous les biens de la famille (par la dictature Pahlavi, cette fois), Ramazan Padar a seulement emporté 100 dollars lorsqu'il est parti en exil à Berlin en 1956, où vivait son frère aîné.
  `,

  "akkordeon.residenzen.headline": `Résidences, art et possessions de la famille `,
  "akkordeon.residenzen.text": `
  La famille impériale a amené avec elle de nombreux amateurs d'art. Plus de 60 palais, d'innombrables joyaux de la couronne et d'autres trésors existent encore. En voici une sélection partielle.

  #### Le blason des Kadjars
  
  Le lion et le soleil ornent le blason des familles Kadjars. Le lion est l'animal national de l'Iran, le soleil levant est un symbole de l'islam chiite.
  
  
  #### Les palais impériaux en Iran : un patrimoine culturel
    
  ##### Palais du Golestan
  
  Le palais du gouvernement des Kadjars a été construit à Téhéran au tournant des 18e et 19e siècles. Depuis 2013, le Palais du Golestan a été inscrit au patrimoine mondial de l'UNESCO.

  Tahmasp Ier, empereur de la dynastie safavide, a commencé la construction de la citadelle, qui a pris de l'importance sous le règne d'Agha Mohammad Khan en tant que résidence officielle des Kadjars. L'architecture et l'agencement du palais ont continué à être développés par les descendants de la dynastie. Aujourd'hui, le palais possède encore une surface de 4,5 hectares et est ouvert au public.

    
  ##### Le complexe de palais de Saadabad
  
  Le complexe de palais de Saadabad comprend 18 palais et environ 110 hectares de terrain. C'était la résidence d'été des empereurs Kadjars. Le plus grand palais, le Palais blanc, et le plus ancien, le Palais vert, font partie des sites spéciaux : on y trouve également le Musée des beaux-arts.
  `,

     // Akkordeon - Wappen
  "akkordeon.wappen.headline": `Le motif du « lion solaire »
  
   ou l'icône de la civilisation turque
`, 
        
   "akkordeon.wappen.text":`
  Selon une idée reçue courante, le motif du « lion solaire », qui ornait le drapeau iranien lors de l'ère Pahlavi, ne serait pas issu des peuples turcs. Une analyse des preuves historiques indique cependant que ses origines sont effectivement ancrées dans la culture et les civilisations des peuples turcs. On retrouve fréquemment le motif du « lion solaire » sur les pièces de monnaie, les pierres tombales, l'architecture, les calices, la verrerie, les tuiles, les drapeaux et autres objets de nombreuses époques de l'histoire et de la mythologie turques.

  Ce motif ne peut en aucun cas être considéré comme appartenant à la culture perse ou à la religion zoroastrienne. Il n'est présent ni à Persépolis, ni à Yazd, ni dans aucun autre haut lieu de la culture perse.
        
  Dans les faits, le démagogue nationaliste persan Muctaba Minevi, qui connaissait les origines turques du motif du « lion solaire », soutenait qu'il devait tout simplement disparaître de la culture iranienne.*
   
  *(Source : Muctabâ Mînevî, Târîh ve Ferheng, Çâp : 3, Şirket-i Sahâmî-yi İntişârât-ı Hârezmî, Tahran, h. ş. 1369, s. 352, 359, 374, 378.)
        
  Les nations turques historiques figurant ci-après ont utilisé le motif du « lion solaire » : 
        
  L'Empire kouchan (30-375), les Turcs shahis (665-850), les Ghaznévides (963-1186), les Seldjoukides (1037-1194), le sultanat de Roum (1077-1307), l'Empire khwarezmien (1077-1231), les mamelouks (1250-1517), l'Ilkhanat (1256-1336), l'Empire ottoman (1299-1922), les Djalayirides (1336-1432), les Timurides (1370-1507), les Aq Qoyunlu (1378-1508), les Qara Qoyunlu (1380-1469), Vijayapura (1490-1686), la dynastie des Séfévides (1501-1736), l'Empire moghol (1526-1858), la dynastie Qutb Shahi (1512-1687), les Afcharides (1736-1796), la dynastie Kadjar (1779-1925), le khanat de Nakhitchevan (1747-1828), le khanat d'Erevan (1747-1828), le Gouvernement de l'Union  iranienne (2 août 1918 - 5 décembre 2018) etc.
        
  En tenant compte de la chronologie suivante, nous pouvons observer de nombreux exemples du motif du « lion solaire » sur différents objets.
        
  ##### 1. Période des Turcs shahis (665-850)
  <img src='img/w_01.jpg'/>

  Le motif du « lion solaire » sur différentes pièces de monnaie de la période des Turcs shahis
        
  ##### 2. Période seldjoukide
  <img src='img/w_02.jpg'/>
        
  Le motif du « lion solaire » sur une pièce de monnaie de la période seldjoukide (1037-1194)
        
  <img src='img/w_03.jpg'/>
        
  Le motif du « lion solaire » pendant la période seldjoukide
        
  <img src='img/w_04.jpg'/>
        
  Le motif du « lion solaire » pendant le sultanat de Roum
        
  <img src='img/w_05.jpg'/>
        
  Le motif du « lion solaire » à l'époque de Kay Khusraw Ier (1240)

  ##### 3. Empire khwarezmien (1077-1231)
  <img src='img/w_06.jpg'/>
        
  Le motif du « lion solaire » sur une pièce de monnaie de l'époque de Jalal ad-Din Mangburni
  
  ##### 4. Période de l'Ilkhanat (1256-1336)
  <img src='img/w_07.jpg'/>
        
  Le motif du « lion solaire » sur une pièce de monnaie de la période de l'Ilkhanat
  
  <img src='img/w_08.jpg'/>
        
  Le motif du « lion solaire » sur une poterie de la période de l'Ilkhanat
  
  ##### 5. Les Timurides (1370-1507)
  <img src='img/w_09.jpg'/>
        
  Sur le drapeau de l'ère des Timurides Motif du « lion solaire » (1423)

  
  <img src='img/w_10.jpg'/>
        
  Le motif du « lion solaire » sur la madrasa Ulugh Beg à Samarcande, en Ouzbékistan

  ##### 6. Empire ottoman (1299-1922)
  <img src='img/w_11.jpg'/>
        
  Le motif du « lion solaire » sur un écusson commémoratif de la victoire de Çanakkale
  
  ##### 7. La dynastie des Séfévides (1501-1736)
  <img src='img/w_12.jpg'/>
        
  Le motif du « lion solaire » sur le drapeau de l'époque de la dynastie des Séfévides
  
  <img src='img/w_13.jpg'/>
        
  Le motif du « lion solaire » sur un drapeau dans une représentation de la visite de l'ambassadeur séfévide Mehmet Riza Beg à Paris
  
  ##### 8. L'Empire moghol (1526-1858)
  <img src='img/w_14.jpg' />
        
  Le motif du « lion solaire » sur le drapeau moghol
  
  <img src='img/w_15.jpg' />
        
  Le motif du « lion solaire » sur un autre drapeau moghol
  
  <img src='img/w_16.jpg' />
        
  Le motif du « lion solaire » sur une pièce de monnaie de l'époque de l'empereur moghol Jahāngīr

  <img src='img/w_17.jpg' />
        
  Le motif du « lion solaire » sur une autre pièce de monnaie de l'époque de l'empereur moghol Jahāngīr

  ##### 9. Dynastie Qutb Shahi (1518-1687)
  <img src='img/w_18.jpg'/>
        
  Le motif du « lion solaire » pendant la dynastie Qutb Shahi
  
  ##### 10. Les Afsharides (1736-1796)
  <img src='img/w_19.jpg'/>
        
  Le motif du « lion solaire » sur le drapeau afsharide
  
  <img src='img/w_20.jpg'/>
        
  Le motif du « lion solaire » sur le sceau de Nader Chah (1746)
  
  ##### 11. Dynastie Kadjar (1779-1925)
  <img src='img/w_21.jpg'/>
        
  Le motif du « lion solaire » sur le drapeau kadjar de l'époque de Mohammad Chah Qadjar
  
  <img src='img/w_22.jpg'/>
        
  Le motif du « lion solaire » sur les drapeaux de l'époque de Fath-Ali Chah Qadjar

  <img src='img/w_23.jpg'/>
        
  Le motif du « lion solaire » sur les pièces de monnaie de l'époque de Fath-Ali Chah Qadjar (1830) 
  
  <img src='img/w_24.jpg'/>
        
  Le motif du « lion solaire » sur le drapeau de l'époque de Mohammad Chah Qadjar

 <img src='img/w_25.jpg'/>
        
  Le motif du « lion solaire » sur le drapeau de l'époque de Nassereddine Chah et dans les périodes ultérieures 

  <img src='img/w_26.jpg'/>
        
  Le motif du « lion solaire » sur un timbre de l'époque de Mohammad Ali Chah Qadjar (1906)

  <img src='img/w_27.jpg'/>
        
  Le motif du « lion solaire » sur une pièce d'argent de l'époque d'Ahmad Chah Qadjar

  <img src='img/w_28.jpg'/>
        
  Blason de l'empire Kadjar 
  
  <img src='img/w_29.jpg'/>
        
  Le motif du « lion solaire » dans un journal Vakayi-i İttifakiye pendant la période kadjar
  
  ##### 12. Khanats des Kadjar
  <img src='img/w_30.jpg'/>
        
  Le motif du « lion solaire » sur les drapeaux présentés au khanat de Nakhitchevan (1747-1828) par Abbas Mirza

  <img src='img/w_31.jpg'/>
        
  Le motif du « lion solaire » sur les drapeaux présentés au khanat de Nakhitchevan (1747-1828) par Abbas Mirza

  <img src='img/w_32.jpg'/>
        
  Le motif du « lion solaire » sur le drapeau du khanat d'Erevan (1747-1828)
  
  ##### 13. La période administrative de l'Union iranienne (2 août 1918 - 5 décembre 1918)
  <img src='img/w_33.jpg'/>
        
  Le motif du « lion solaire » sur le drapeau du Gouvernement de l'Union iranienne (2 août 1918 - 5 décembre 1918)
  
  ##### 14. Tapis turkmènes
  <img src='img/w_34.jpg'/>
        
  Le motif du « lion solaire » sur des tapisseries turkmènes
  
  ##### 15. Devises
  <img src='img/w_35.jpg'/>
        
  Le motif du « lion solaire » sur les devises de la République d'Ouzbékistan
    
`,

  "akkordeon.images.headline": "des photos"
}